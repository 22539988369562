// The function needs to support getting base languages from cases like:
// 'en-US' => ['en'],
// 'en-US-client' => ['en-US', 'en']
// 'en--client' => ['en']
import {remove} from '../util/arr-util';

export function getBaseLanguages(locale: string | null): string[] {
  const parts = locale?.split('-') ?? [];
  switch (parts.length) {
    case 3:
      // Filter is required to remove empty part from double dash cases (e.g. en--client).
      const result = [parts.slice(0, 2).filter(remove.falsy).join('-')];
      // Add first part only if it's not empty and if it is not already included (e.g. for en--client the array
      // already contains 'en' and we must not add it again).
      if (!result.includes(parts[0]) && parts[0]) {
        result.push(parts[0]);
      }
      return result;
    case 2:
      return [parts[0]];
    default:
      // All the usages (autogen, i18n-utils) already explicitly falls back to 'en', so we can return empty array.
      return [];
  }
}
