import {Socket} from 'socket.io-client';
import {ClockI} from '../../../src/Clock';
import {AuthI, GetAuthToken} from '../../../src/CommonApis';
import {FetcherFunc, createAuthedFetcher} from '../../../src/FetcherFunc';
import {FollowLayerCb, FollowedLayer, StopCb, getFollowLayer} from '../../../src/follow-layer';
import {I18nFunction} from '../../../src/i18n/i18n';
import {initI18n} from '../../../src/i18n/i18n-util';
import {initCurUser} from '../../../src/initCurUser';
import {HarvestYear} from '../../../src/models/interfaces';
import {syncCrops} from '../../../src/redux/actions/crops';
import {bindTodaysDate} from '../../../src/redux/actions/db';
import {setDbFilter} from '../../../src/redux/actions/filters';
import {ThunkStore} from '../../../src/redux/types';
import {getCustomers} from '../../../src/util/user-group-util';
import {Actions, State, getStore} from '../redux';
import {reportErr} from '../util/err';
import {getLocale} from './i18n';

export interface ApiServices {
  store: ThunkStore<State, Actions>;
  authedFetcher: FetcherFunc;
  followLayer: (layer: FollowedLayer, cb: FollowLayerCb) => null | StopCb;
  t: I18nFunction;
  locale: string;
}

function socketHandshake(socket: Socket, getAuthToken: GetAuthToken) {
  socket.on('connect', async () => {
    console.info('Socket connected, handshaking.');
    socket.emit('handshake', await getAuthToken());
  });
  socket.on('disconnect', () => {
    console.info('Socket disconnected.');
  });

  socket.connect();
}

export async function createServices(
  auth: AuthI,
  clock: ClockI,
  fetcher: FetcherFunc,
  socket: Socket,
): Promise<ApiServices> {
  const locale = getLocale(auth);
  const store = await getStore();
  await initCurUser(auth.getAuthToken, store);
  const authedFetcher = createAuthedFetcher(auth.getAuthToken, fetcher);
  const userGroups = await authedFetcher({method: 'GET', path: 'api/user_group'});
  store.dispatch({type: 'SET_USER_GROUPS', userGroups: userGroups});
  const t = initI18n(locale, getCustomers(store.getState().dbMeta.curUser?.email, userGroups));
  store.dispatch(bindTodaysDate(clock));
  store.dispatch(syncCrops(clock, t, authedFetcher)).catch(reportErr);
  const today = new Date(clock.now());
  const year = String(today.getUTCFullYear()) as HarvestYear;
  store.dispatch(setDbFilter({harvest_year: [year]}));
  socketHandshake(socket, auth.getAuthToken);
  const followLayer = getFollowLayer(socket);
  return {store, authedFetcher, followLayer, t, locale};
}
