import {Col, Row} from 'antd';
import React, {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useFormy} from '../../../src/Formy/hooks';
import {Flags, getEnabledFlags} from '../../../src/feature-flags';
import {SubsidyConfiguration} from '../../../src/models/subsidy-configuration';
import {getPreferredUserGroups} from '../../../src/selectors/userGroups';
import {FormyDateRange} from '../Formy/FormyDateRange';
import {FormyEnum} from '../Formy/FormyEnum';
import {Label} from '../Formy/FormyLabel';
import {FormySubmit} from '../Formy/FormySubmit';
import {FormyTextStr} from '../Formy/FormyText';
import {useApis} from '../apis/ApisContext';

// Subsidy config is specific to Brazilian market, and we want to show it only if the user has subsidyConfig flag
// and is part of some Brazil user group.
export function showSubsidyConfig(flags: Set<Flags>): boolean {
  return flags.has('subsidyConfig') && flags.has('hasSomeBrazilUserGroup');
}

const marginBottom10 = {marginBottom: '10px'};
const marginBottom20 = {marginBottom: '20px'};
const marginLeft10 = {marginLeft: '10px'};

const SUBSIDY_REQUEST_TYPE = 'subsidy-config';

export const SubsidyConfig: FC = () => {
  const {t, authedFetcher, store} = useApis();
  const flags = useSelector(getEnabledFlags);
  const getSubsidyConfig = useCallback(
    async (userGroup: string) => {
      return await authedFetcher({
        method: 'GET',
        path: 'api/request',
        params: [
          ['request_type', `eq.${SUBSIDY_REQUEST_TYPE}`],
          ['user_group', `eq.${userGroup}`],
        ],
      });
    },
    [authedFetcher],
  );
  const formy = useFormy(
    'new',
    async () => {
      return {
        user_group: null,
        claim_processing_date_from: null,
        claim_processing_date_to: null,
        claim_processing_per_minute: null,
        policy_processing_date_from: null,
        policy_processing_date_to: null,
        policy_processing_per_minute: null,
        quote_processing_date_from: null,
        quote_processing_date_to: null,
        quote_processing_per_minute: null,
      };
    },
    t,
    async (
      formy: SubsidyConfiguration & {
        user_group: string | null;
      },
    ) => {
      const {user_group, ...formyRequest} = formy;
      // Re-fetch config in case it changed in the meantime.
      // We can assume user_group is defined, otherwise validation would have failed and prevented onSubmit.
      const config = await getSubsidyConfig(formy.user_group!);
      if (config.length) {
        const params: [string, string][] = [
          ['request_type', `eq.${SUBSIDY_REQUEST_TYPE}`],
          ['user_group', `eq.${formy.user_group}`],
        ];
        await authedFetcher({
          method: 'PATCH',
          path: 'api/request',
          params: params,
          json_body: {request: formyRequest},
        });
      } else {
        await authedFetcher({
          method: 'POST',
          path: 'api/request',
          json_body: {
            user_group: formy.user_group,
            request_type: SUBSIDY_REQUEST_TYPE,
            request: formyRequest,
          },
        });
      }
    },
    x => {
      return {
        // We allow saving of empty or partial formy as long as the user group is defined.
        user_group: !x.user_group,
      };
    },
    null,
  );
  const onChangeUserGroup = useCallback(
    async (userGroup: string | null) => {
      // Always update user_group, because we overrode onChange property.
      formy?.getChangeHandler('user_group')(userGroup);
      if (!userGroup) {
        return;
      }
      const config = await getSubsidyConfig(userGroup);
      formy?.getChangeHandler('claim_processing_date_from')(config[0]?.request.claim_processing_date_from);
      formy?.getChangeHandler('claim_processing_date_to')(config[0]?.request.claim_processing_date_to);
      formy?.getChangeHandler('claim_processing_per_minute')(config[0]?.request.claim_processing_per_minute);
      formy?.getChangeHandler('policy_processing_date_from')(config[0]?.request.policy_processing_date_from);
      formy?.getChangeHandler('policy_processing_date_to')(config[0]?.request.policy_processing_date_to);
      formy?.getChangeHandler('policy_processing_per_minute')(config[0]?.request.policy_processing_per_minute);
      formy?.getChangeHandler('quote_processing_date_from')(config[0]?.request.quote_processing_date_from);
      formy?.getChangeHandler('quote_processing_date_to')(config[0]?.request.quote_processing_date_to);
      formy?.getChangeHandler('quote_processing_per_minute')(config[0]?.request.quote_processing_per_minute);
    },
    [formy, getSubsidyConfig],
  );

  if (!formy || !showSubsidyConfig(flags)) {
    return null;
  }
  return (
    <>
      <Row style={marginBottom10}>
        <Col span={8}>
          <Label>{t('Portfolio')}</Label>
          <FormyEnum
            selectMsg={'SelectPortfolio'}
            field="user_group"
            formy={formy}
            options={getPreferredUserGroups(store.getState()).map(value => [value, value])}
            onChange={onChangeUserGroup}
          />
        </Col>
      </Row>
      <Row style={marginBottom10}>
        <Col span={8}>
          <Label>{t('QuoteProcessingStartEnd')}</Label>
          <FormyDateRange
            formy={formy}
            range_from_field={'quote_processing_date_from'}
            range_to_field={'quote_processing_date_to'}
          />
        </Col>
        <Col span={4} style={marginLeft10}>
          <Label>{t('MaxSubsidiesPerMinute')}</Label>
          <FormyTextStr field={'quote_processing_per_minute'} formy={formy} />
        </Col>
      </Row>
      <Row style={marginBottom10}>
        <Col span={8}>
          <Label>{t('PolicyProcessingStartEnd')}</Label>
          <FormyDateRange
            formy={formy}
            range_from_field={'policy_processing_date_from'}
            range_to_field={'policy_processing_date_to'}
          />
        </Col>
        <Col span={4} style={marginLeft10}>
          <Label>{t('MaxSubsidiesPerMinute')}</Label>
          <FormyTextStr field={'policy_processing_per_minute'} formy={formy} />
        </Col>
      </Row>
      <Row style={marginBottom10}>
        <Col span={8}>
          <Label>{t('ClaimProcessingStartEnd')}</Label>
          <FormyDateRange
            formy={formy}
            range_from_field={'claim_processing_date_from'}
            range_to_field={'claim_processing_date_to'}
          />
        </Col>
        <Col span={4} style={marginLeft10}>
          <Label>{t('MaxSubsidiesPerMinute')}</Label>
          <FormyTextStr field={'claim_processing_per_minute'} formy={formy} />
        </Col>
      </Row>
      <Row>
        <Col style={marginBottom20}>
          <FormySubmit label="Save" formy={formy} />
        </Col>
      </Row>
    </>
  );
};
