// @formatter:off
import {Geometry, MultiPolygon, Polygon} from 'geojson';
import {DateRange, Uuid} from './types';
import {FetcherFunc} from '../FetcherFunc';
import {transportToFarm, transportToField, transportToHarvest, transportToHarvestData, transportToPolicy, transportToSample, transportToVisitLite} from './serialization';
import {LngLat} from '../geo';
import {parseComposite} from '../sql/pg-value-parsers';

export const compositeParsers: {[k: string]: (arr: any[]) => Record<string, any>} = {};
export const AreaUnit = [
'hectares', 'acres'
] as const;
export type AreaUnit = typeof AreaUnit[number];

export const ClaimStatus = [
'created-unassigned', 'awaiting-adjuster-acceptance', 'adjuster-accepted', 'visit-date-defined', 'visit-samples-collected', 'visit-report-created', 'assessment-in-progress', 'payment-proposal-sent', 'completed', 'rejected'
] as const;
export type ClaimStatus = typeof ClaimStatus[number];

export const CostType = [
'resowing-costs', 'additional-costs', 'compensation-costs', 'production-costs', 'harvesting-costs', 'castration-costs', 'purification-costs'
] as const;
export type CostType = typeof CostType[number];

export const CountryCode = [
'AGO', 'ARG', 'AUS', 'AUT', 'BDB', 'BDI', 'BEL', 'BEN', 'BFA', 'BGR', 'BLZ', 'BOL', 'BRA', 'BWA', 'CAN', 'CHE', 'CHL', 'CHN', 'CIV', 'COL', 'CZE', 'DEU', 'ECU', 'EGY', 'ERI', 'ESP', 'EST', 'ETH', 'FIN', 'FRA', 'GBR', 'GEO', 'GHA', 'GIN', 'GMB', 'GRC', 'HUN', 'IDN', 'IND', 'ITA', 'KAZ', 'KEN', 'KOR', 'LTU', 'LVA', 'MAR', 'MDA', 'MDG', 'MEX', 'MLI', 'MOZ', 'MRT', 'MWI', 'NCL', 'NER', 'NGA', 'NZL', 'PAK', 'PER', 'POL', 'PRT', 'PRY', 'ROU', 'RUS', 'RWA', 'SDN', 'SEN', 'SOM', 'SRB', 'SVK', 'SVN', 'TCD', 'TGO', 'THA', 'TUR', 'TWN', 'TZA', 'UGA', 'UKR', 'URY', 'USA', 'ZAF', 'ZMB', 'ZWE'
] as const;
export type CountryCode = typeof CountryCode[number];

export const CoverageType = [
'basic', 'replanting'
] as const;
export type CoverageType = typeof CoverageType[number];

export const CropCondition = [
'not_planted', 'very_poor', 'poor', 'normal', 'good', 'very_good', 'harvested'
] as const;
export type CropCondition = typeof CropCondition[number];

export const DensityUnit = [
'units-per-m2', 'units-per-ft2', 'units-per-hectare', 'units-per-acre'
] as const;
export type DensityUnit = typeof DensityUnit[number];

export const DistanceUnit = [
'meters', 'centimeters', 'inches', 'feet'
] as const;
export type DistanceUnit = typeof DistanceUnit[number];

export const EventLogMode = [
'web', 'mobile'
] as const;
export type EventLogMode = typeof EventLogMode[number];

export const EventSource = [
'eswd', 'noaa', 'gt-sample'
] as const;
export type EventSource = typeof EventSource[number];

export const EventType = [
'hail'
] as const;
export type EventType = typeof EventType[number];

export const GadmLevel = [
'0', '1', '2', '3', '4', '5'
] as const;
export type GadmLevel = typeof GadmLevel[number];

export const HarvestCrop = [
'unknown', 'sugar-beet', 'sunflower', 'corn-grain', 'barley', 'soybeans', 'rapeseed', 'wheat', 'cotton', 'corn-silage', 'peas', 'grapes', 'apples', 'pears', 'sugar-cane', 'olives', 'walnuts', 'beans', 'sorghum', 'rice', 'lentils', 'linseed', 'triticale', 'potatoes', 'oats', 'buckwheat', 'cauliflowers', 'onions', 'tomatoes', 'other-vegetables', 'grassland', 'rye', 'mustard', 'wheat-hard', 'barley-malting', 'spelt', 'tobacco', 'chickpeas', 'millet', 'hemp', 'mixed-cereal', 'mangos', 'sorghum-silage', 'peanuts', 'peaches', 'apricots', 'nectarines', 'cherries', 'corn-seeds', 'sunflower-seeds'
] as const;
export type HarvestCrop = typeof HarvestCrop[number];

export const HarvestSeason = [
'spring', 'winter', 'first-crop', 'second-crop', 'cover-crop', 'perennial'
] as const;
export type HarvestSeason = typeof HarvestSeason[number];

export const HarvestYear = [
'2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008', '2007', '2006', '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997', '1996', '1995', '1994', '1993', '1992', '1991', '1990', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035'
] as const;
export type HarvestYear = typeof HarvestYear[number];

export const LayerType = [
'vegetation', 'soil-moisture', 'intrafield', 'interfield', 'temperature', 'crop-mon', 'hail', 'flood-risk', 'precipitation', 'wind', 'interyield', 'surface-temperature', 'custom-a', 'rainstorm'
] as const;
export type LayerType = typeof LayerType[number];

export const LossCause = [
'pest', 'disease', 'snow', 'drought', 'frost', 'unknown', 'flood', 'none', 'hail', 'weeds', 'wild-animals', 'storm', 'heavy-rainfall', 'sunburn', 'excess-water', 'downpour', 'excessive-air-humidity', 'heatstroke', 'cold', 'lack-of-sunlight', 'tornado', 'sandstorm', 'fire', 'other-loss', 'decree', 'heat-wave'
] as const;
export type LossCause = typeof LossCause[number];

export const MembershipType = [
'full', 'group-allocator', 'user-admin', 'static-data'
] as const;
export type MembershipType = typeof MembershipType[number];

export const PolicyStatus = [
'quote_created', 'quote_rejected', 'quote_accepted', 'policy'
] as const;
export type PolicyStatus = typeof PolicyStatus[number];

export const RegionLevel = [
'country', 'state', 'commune'
] as const;
export type RegionLevel = typeof RegionLevel[number];

export const RequestStatus = [
'new', 'processing', 'ok', 'warning', 'error'
] as const;
export type RequestStatus = typeof RequestStatus[number];

export const RequestType = [
'soil-moisture-index', 'mission-import', 'visit-report', 'send-notification', 'subsidy', 'subsidy-config', 'ecampo-insurance-proposal'
] as const;
export type RequestType = typeof RequestType[number];

export const SentinelInterpolation = [
'not-processed', 'week2-processed', 'week1-processed', 'fully-processed', 's1-extrapolation', 's1-extrapolation-needed'
] as const;
export type SentinelInterpolation = typeof SentinelInterpolation[number];

export const SentinelRequestType = [
'sentinel1-statistics', 'sentinel2-statistics'
] as const;
export type SentinelRequestType = typeof SentinelRequestType[number];

export const UnitPriceUnit = [
'eur-per-ton', 'eur-per-hectoliter', 'brl-per-bag'
] as const;
export type UnitPriceUnit = typeof UnitPriceUnit[number];

export const UpdateType = [
'deletion', 'force_reload'
] as const;
export type UpdateType = typeof UpdateType[number];

export const VegetationStage = [
'vegetative-growth', 'harvest', 'germination', 'flowering', 'ripening', 'senescence', 'fruit-development', 'inflorescence', 'development', 'side-shoot', 'leaf-development'
] as const;
export type VegetationStage = typeof VegetationStage[number];

export const VisitCandidateStatus = [
'assigned', 'accepted', 'rejected'
] as const;
export type VisitCandidateStatus = typeof VisitCandidateStatus[number];

export const VisitType = [
'preinspection-visit', 'validation-visit', 'monitoring-visit', 'claims-visit'
] as const;
export type VisitType = typeof VisitType[number];

export const WeightUnit = [
'grams', 'kilograms', 'ounces', 'thousand-kernel-weight-grams', 'bags'
] as const;
export type WeightUnit = typeof WeightUnit[number];

export const YieldPredictionModel = [
'kernel-rbf-yearly', 'linear-regression-weekly', 'linear-regression-sm-jrc-weekly', 'linear-regression-ndvi-weekly', 'linear-regression-aggregated-weekly'
] as const;
export type YieldPredictionModel = typeof YieldPredictionModel[number];

export const YieldUnit = [
'tons-per-hectare', 'bushels-per-acre', 'hectoliters-per-hectare', 'bags-per-hectare', 'arrobas-bra', 'bales-per-acre', 'percent', 'tons-per-acre', 'decitons-per-hectare', 'kilograms-per-hectare'
] as const;
export type YieldUnit = typeof YieldUnit[number];


export interface AreaValue {
  val: number;
  unit: AreaUnit;
}
compositeParsers.area_value = (arr: any[]): AreaValue => ({
  val: arr[0],
  unit: arr[1],
});

export interface Cost {
  cost: number|null;
  cost_type: CostType|null;
}
compositeParsers.cost = (arr: any[]): Cost => ({
  cost: arr[0],
  cost_type: arr[1],
});

export interface DensityValue {
  val: number;
  unit: DensityUnit;
}
compositeParsers.density_value = (arr: any[]): DensityValue => ({
  val: arr[0],
  unit: arr[1],
});

export interface DistanceValue {
  val: number;
  unit: DistanceUnit;
}
compositeParsers.distance_value = (arr: any[]): DistanceValue => ({
  val: arr[0],
  unit: arr[1],
});

export interface File {
  uri: string;
  name: string;
  added_on: string;
  mime_type: string|null;
}
compositeParsers.file = (arr: any[]): File => ({
  uri: arr[0],
  name: arr[1],
  added_on: arr[2],
  mime_type: arr[3],
});

export interface Loss {
  loss: YieldValue|null;
  loss_cause: LossCause|null;
  loss_event_date: string|null;
  loss_recognized: boolean|null;
}
compositeParsers.loss = (arr: any[]): Loss => ({
  loss: arr[0] ? compositeParsers.yield_value(parseComposite(arr[0])) as YieldValue : null,
  loss_cause: arr[1],
  loss_event_date: arr[2],
  loss_recognized: arr[3],
});

export interface SampleImage {
  uri: string;
  latitude: number|null;
  longitude: number|null;
  accuracy: number|null;
  altitude: number|null;
  altitude_accuracy: number|null;
  heading: number|null;
  mocked: boolean|null;
}
compositeParsers.sample_image = (arr: any[]): SampleImage => ({
  uri: arr[0],
  latitude: arr[1],
  longitude: arr[2],
  accuracy: arr[3],
  altitude: arr[4],
  altitude_accuracy: arr[5],
  heading: arr[6],
  mocked: arr[7],
});

export interface UnitPriceValue {
  val: number;
  unit: UnitPriceUnit;
}
compositeParsers.unit_price_value = (arr: any[]): UnitPriceValue => ({
  val: arr[0],
  unit: arr[1],
});

export interface VisitCandidate {
  email: string|null;
  visit_candidate_status: VisitCandidateStatus|null;
  updated_at: string|null;
}
compositeParsers.visit_candidate = (arr: any[]): VisitCandidate => ({
  email: arr[0],
  visit_candidate_status: arr[1],
  updated_at: arr[2]?.replace(' ', 'T') + ':00',
});

export interface WeightValue {
  val: number;
  unit: WeightUnit;
}
compositeParsers.weight_value = (arr: any[]): WeightValue => ({
  val: arr[0],
  unit: arr[1],
});

export interface YieldValue {
  val: number;
  unit: YieldUnit;
}
compositeParsers.yield_value = (arr: any[]): YieldValue => ({
  val: arr[0],
  unit: arr[1],
});

export interface Filters {
  farm_ids: string[]|null;
  policy_ids: string[]|null;
  user_groups: string[]|null;
  crop_ids: string[]|null;
  years: HarvestYear[]|null;
  sampled_by: string[]|null;
  loss_causes: LossCause[]|null;
  crop_conditions: CropCondition[]|null;
  sample_date_from: string|null;
  sample_date_to: string|null;
  emergence_date_from: string|null;
  emergence_date_to: string|null;
  max_vi_date_from: string|null;
  max_vi_date_to: string|null;
  max_vi_value_from: number|null;
  max_vi_value_to: number|null;
  region_ids: string[]|null;
  claim_ids: string[]|null;
  visit_types: VisitType[]|null;
}
compositeParsers.filters = (arr: any[]): Filters => ({
  farm_ids: arr[0],
  policy_ids: arr[1],
  user_groups: arr[2],
  crop_ids: arr[3],
  years: arr[4],
  sampled_by: arr[5],
  loss_causes: arr[6],
  crop_conditions: arr[7],
  sample_date_from: arr[8],
  sample_date_to: arr[9],
  emergence_date_from: arr[10],
  emergence_date_to: arr[11],
  max_vi_date_from: arr[12],
  max_vi_date_to: arr[13],
  max_vi_value_from: arr[14],
  max_vi_value_to: arr[15],
  region_ids: arr[16],
  claim_ids: arr[17],
  visit_types: arr[18],
});

export interface RegionInfo {
  region_level: string|null;
  region_id: string;
  region_name: string|null;
}
compositeParsers.region_info = (arr: any[]): RegionInfo => ({
  region_level: arr[0],
  region_id: arr[1],
  region_name: arr[2],
});

export interface Claim {
  claim_id: Uuid;
  added_on: string;
  added_by: string;
  updated_at: string;
  external_claim_id: string|null;
  policy_id: Uuid|null;
  farm_id: Uuid;
  manager_first_name: string|null;
  manager_last_name: string|null;
  manager_phone: string|null;
  manager_email: string|null;
  contact_first_name: string|null;
  contact_last_name: string|null;
  contact_phone: string|null;
  contact_email: string|null;
  coverage_type: CoverageType|null;
  assigned_to: VisitCandidate[];
  status: ClaimStatus|null;
  closed_on: string|null;
  comments: string|null;
  metadata: any|null;
  custom_columns: Record<string, any>|null;
  harvest_year: HarvestYear;
}

export interface ClaimDamage {
  claim_damage_id: Uuid;
  added_on: string;
  added_by: string;
  updated_at: string;
  claim_id: Uuid;
  insurance_loss_estimation: Loss[]|null;
  vegetation_stage: VegetationStage|null;
  harvest_id: Uuid;
}

export interface Crop {
  crop_id: string;
  harvest_crop: HarvestCrop|null;
  name: string|null;
  viewers: string[]|null;
}

export interface CropMapping {
  identifier: string;
  origin_id: string;
  crop_id: string|null;
  properties: any /* TODO(autogen): json,pg_catalog,json */|null;
  viewers: (string|null)[]|null;
}

export interface CropMonRegions {
  region_id: string;
  name: string|null;
  country_code: string|null;
}

export interface CropMonitoring {
  type: string|null;
  country_code: string|null;
  harvest_crop: HarvestCrop|null;
  harvest_season: HarvestSeason|null;
  harvest_year: HarvestYear|null;
  canonical_date: string|null;
  data: { [region_id: string]: number };
}

export interface CropMonitoringAvailability {
  country_code: string|null;
  harvest_crop: HarvestCrop;
  harvest_season: HarvestSeason;
  harvest_year: HarvestYear;
}

export interface CropMonitoringNational {
  country_code: string|null;
  harvest_crop: HarvestCrop;
  harvest_season: HarvestSeason;
  harvest_year: HarvestYear;
  series: any /* TODO(autogen): json,pg_catalog,json */|null;
}

export interface CropMonitoringRegionAvailability {
  region_id: string;
  harvest_crop: HarvestCrop;
  harvest_season: HarvestSeason;
  harvest_year: HarvestYear;
}

export interface CropMonitoringRegional {
  region_id: string;
  harvest_crop: HarvestCrop;
  harvest_season: HarvestSeason;
  harvest_year: HarvestYear;
  series: any /* TODO(autogen): json,pg_catalog,json */|null;
}

export interface CropMonitoringRegionalBestPredictions {
  region_id: string;
  harvest_crop: HarvestCrop;
  harvest_season: HarvestSeason;
  harvest_year: HarvestYear;
  series: any /* TODO(autogen): json,pg_catalog,json */|null;
}

export interface EventLog {
  email: string;
  event_name: string;
  ts: string;
  mode: EventLogMode;
  session_id: Uuid;
  ip: string|null;
  user_agent: string|null;
  url: string|null;
  cur_screen: string|null;
  cur_screen_params: any /* TODO(autogen): json,pg_catalog,json */|null;
  locale: string|null;
  os: string|null;
  version: string|null;
  device: string|null;
  battery_percent: number|null;
  used_memory_mb: number|null;
  total_memory_mb: number|null;
  screen_width: number|null;
  screen_height: number|null;
  props: any /* TODO(autogen): json,pg_catalog,json */|null;
}

export interface Farm {
  farm_id: Uuid;
  user_group: string;
  added_on: string;
  added_by: string|null;
  updated_at: string;
  external_farm_id: string|null;
  address: string|null;
  farm_location: LngLat|null;
  farm_name: string|null;
  comments: string|null;
  editors: string[];
  farmer_email: string|null;
  metadata: any|null;
  farmer_name: string|null;
  custom_columns: Record<string, any>|null;
  telepac_id: string|null;
  merged_ids: (string|null)[]|null;
  attachments: File[];
}

export interface Field {
  field_id: Uuid;
  added_on: string;
  added_by: string|null;
  updated_at: string;
  farm_id: Uuid;
  external_field_id: string|null;
  field_location: LngLat|null;
  field_shape: Polygon|null;
  user_location: LngLat|null;
  comments: string|null;
  field_area: AreaValue|null;
  metadata: any|null;
  custom_columns: Record<string, any>|null;
  region_id: string|null;
  merged_ids: (string|null)[]|null;
}

export interface FieldData {
  field_id: Uuid;
  crop_id: string;
  updated_at: string;
  region_id: string|null;
  avg_homogeneity: number|null;
  homogeneity_score: number|null;
  max_ndvi: number|null;
  productivity_score: number|null;
  vol_ndvi: number|null;
  stability_score: number|null;
  relative_productivity_score: number|null;
  relative_stability_score: number|null;
  percentile_productivity_score: number|null;
  percentile_stability_score: number|null;
  region_name: string|null;
}

export interface FieldSeriesData {
  field_id: Uuid;
  crop_id: string;
  harvest_year: HarvestYear;
  updated_at: string;
  cloudy_weeks_pct: number|null;
  avg_homogeneity: number|null;
  max_ndvi: number|null;
  crop_cover_pct: number|null;
}

export interface FieldSeriesItem {
  field_id: Uuid|null;
  canonical_date: string|null;
  item: any /* TODO(autogen): json,pg_catalog,json */|null;
  max_updated_at: string|null;
  farm_id: Uuid|null;
  harvest_id: Uuid|null;
  harvest_year: HarvestYear|null;
  emergence_date: string|null;
  max_vi_date: string|null;
  harvest_range: DateRange|null;
}

export interface FieldShapes {
  field_shape: Polygon|null;
  properties: any /* TODO(autogen): json,pg_catalog,json */|null;
  identifier: string;
  field_shape_id: number;
  updated_at: string;
}

export interface Harvest {
  harvest_id: Uuid;
  added_on: string;
  added_by: string|null;
  updated_at: string;
  field_id: Uuid|null;
  policy_id: Uuid|null;
  harvest_year: HarvestYear|null;
  insured_yield: YieldValue|null;
  irrigated: boolean|null;
  metadata: any|null;
  crop_id: string|null;
  harvest_area: AreaValue|null;
  farm_id: Uuid|null;
  variety: string|null;
  organic: boolean|null;
  comments: string|null;
  insured_price: UnitPriceValue|null;
  custom_columns: Record<string, any>|null;
  insured_area: AreaValue|null;
  external_harvest_id: string|null;
  merged_ids: (string|null)[]|null;
  insured_percent: number|null;
  commodity_price: UnitPriceValue|null;
  reference_yield: YieldValue|null;
  premium_rate_percent: number|null;
}

export interface HarvestData {
  harvest_id: Uuid;
  emergence_date: string|null;
  max_vi_date: string|null;
  max_vi_value: number|null;
  updated_at: string;
  harvest_range: DateRange|null;
  farm_id: Uuid;
  field_id: Uuid|null;
  policy_id: Uuid|null;
  user_group: string;
  editors: string[];
  field_area_ha: number|null;
  harvest_area_ha: number|null;
  crop_id: string|null;
  harvest_year: HarvestYear|null;
  yield_area_ha: number|null;
  harvest_yield_t_ha: number|null;
  sample_added_by: (string|null)[]|null;
  sample_dates: (string|null)[]|null;
  sample_loss_causes: LossCause[]|null;
  sample_crop_conditions: (CropCondition|null)[]|null;
  worker_max_updated_at: string|null;
  granular_region_id: string|null;
  feasible_yield_t_ha: number|null;
  total_loss_yield_t_ha: number|null;
  insured_yield_t_ha: number|null;
  insured_area_ha: number|null;
  sampled_area_ha: number|null;
  loss_by_cause: Loss[]|null;
}

export interface HarvestSeriesData {
  harvest_id: Uuid;
  canonical_date: string;
  predicted_yield: YieldValue;
  updated_at: string;
  model_version: string;
}

export interface HarvestTimeseriesPackage {
  farm_id: Uuid;
  field_id: Uuid|null;
  harvest_id: Uuid;
  harvest_year: HarvestYear|null;
  series: any /* TODO(autogen): json,pg_catalog,json */|null;
  emergence_date: string|null;
  max_vi_date: string|null;
  predicted_ndvi_dates: (string|null)[]|null;
}

export interface Policy {
  policy_id: Uuid;
  user_group: string;
  added_on: string;
  added_by: string|null;
  updated_at: string;
  policy_number: string;
  comments: string|null;
  editors: string[];
  metadata: any|null;
  custom_columns: Record<string, any>|null;
  merged_ids: (string|null)[]|null;
  status: PolicyStatus;
}

export interface Regions {
  country_code: string|null;
  origin_id: string;
  gadm_level: GadmLevel|null;
  shape: MultiPolygon|null;
  identifier: string;
  properties: any /* TODO(autogen): json,pg_catalog,json */|null;
  region_id: string;
  name: string|null;
  updated_at: string|null;
  region_info: (RegionInfo|null)[]|null;
}

export interface Request {
  request_id: Uuid;
  updated_at: string;
  user_group: string;
  request: any /* TODO(autogen): json,pg_catalog,json */;
  request_type: RequestType;
  response: any /* TODO(autogen): json,pg_catalog,json */|null;
  status: RequestStatus;
}

export interface Sample {
  sample_id: Uuid;
  added_on: string;
  added_by: string|null;
  updated_at: string;
  external_sample_id: string|null;
  harvest_id: Uuid;
  sample_date: string|null;
  sample_location: LngLat|null;
  crop_condition: CropCondition|null;
  user_location: LngLat|null;
  vegetation_stage: VegetationStage|null;
  sowing_date: string|null;
  herbicide: boolean|null;
  insecticide: boolean|null;
  fungicide: boolean|null;
  comments: string|null;
  sample_shape: Geometry|null;
  images: SampleImage[];
  estimated_yield: YieldValue|null;
  feasible_yield: YieldValue|null;
  sample_area: AreaValue|null;
  metadata: any|null;
  losses: Loss[];
  sowing_density: DensityValue|null;
  costs: Cost[];
  plant_density: DensityValue|null;
  space_between_rows: DistanceValue|null;
  harvester_platform_width: DistanceValue|null;
  harvester_distance_collected: DistanceValue|null;
  harvester_gross_sample: WeightValue|null;
  units_per_plant: number|null;
  kernels_per_unit: number|null;
  weight_per_unit: WeightValue|null;
  vinification_ratio: number|null;
  harvest_loss_pct: number|null;
  impurities_pct: number|null;
  grain_moisture_pct: number|null;
  grain_moisture_reduction_pct: number|null;
  custom_columns: Record<string, any>|null;
  space_between_plants: DistanceValue|null;
  ear_density: DensityValue|null;
  conversion_ratio: number|null;
  sample_location_accuracy: number|null;
  attachments: File[];
  dead_plants_pct: number|null;
  row_distance_collected: DistanceValue|null;
  row_plants_collected: number|null;
}

export interface Suggestion {
  suggestion_id: Uuid|null;
  viewers: (string|null)[]|null;
  external_farm_id: string|null;
  address: string|null;
  farm_name: string|null;
  farm_location: Geometry|null;
  policy_number: string|null;
  metadata: any|null;
  updated_at: string|null;
  telepac_id: string|null;
}

export interface UpdateLog {
  update_type: UpdateType|null;
  updated_at: string|null;
  affected_table: string|null;
  uuid: Uuid|null;
  user_group: string;
}

export interface UserEntity {
  user_id: Uuid;
  email: string|null;
  username: string|null;
  enabled: boolean|null;
  email_verified: boolean|null;
  first_name: string|null;
  last_name: string|null;
  added_on: string|null;
  customers: (string|null)[]|null;
}

export interface UserGroup {
  user_group: string;
  preferred_owner: boolean|null;
  name: string|null;
  countries: CountryCode[];
  grantors: (string|null)[];
}

export interface UserGroupMembership {
  user_group: string;
  email: string;
  membership_type: MembershipType;
}

export interface Visit {
  visit_id: Uuid;
  added_on: string;
  added_by: string|null;
  sample_dates: string[]|null;
  visit_type: VisitType|null;
  comments: string|null;
  withdrawal: boolean|null;
  signed_by_farmer: boolean|null;
  signed_report: string|null;
  attachments: string[]|null;
  updated_at: string;
  closed: boolean|null;
  metadata: any|null;
  custom_columns: Record<string, any>|null;
  closed_on: string|null;
  external_visit_id: string|null;
  withdrawal_crop_ids: string[]|null;
  signature_crop_ids: string[]|null;
  claim_id: Uuid;
  visit_date: string|null;
  visit_report_uri: string|null;
  harvest_ids: string[];
}

export interface VisitLite {
  visit_id: Uuid;
  added_on: string;
  added_by: string|null;
  sample_dates: string[]|null;
  visit_type: VisitType|null;
  comments: string|null;
  withdrawal: boolean|null;
  signed_by_farmer: boolean|null;
  updated_at: string;
  closed: boolean|null;
  metadata: any|null;
  custom_columns: Record<string, any>|null;
  closed_on: string|null;
  external_visit_id: string|null;
  withdrawal_crop_ids: string[]|null;
  signature_crop_ids: string[]|null;
  claim_id: Uuid;
  visit_date: string|null;
  visit_report_uri: string|null;
  harvest_ids: string[];
}

export interface AssignFarmsToUsersRequest {
  farm_ids: (string|null)[];
  assigned_editors: (string|null)[];
}

export function assignFarmsToUsers(
  authedFetcher: FetcherFunc,
  req: AssignFarmsToUsersRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/assign_farms_to_users',
    json_body: req
  });
}

export interface AssignPoliciesToUsersRequest {
  policy_ids: (string|null)[];
  assigned_editors: (string|null)[];
}

export function assignPoliciesToUsers(
  authedFetcher: FetcherFunc,
  req: AssignPoliciesToUsersRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/assign_policies_to_users',
    json_body: req
  });
}

export interface ChangeAdminGroupRequest {
  user_group: string;
}

export function changeAdminGroup(
  authedFetcher: FetcherFunc,
  req: ChangeAdminGroupRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/change_admin_group',
    json_body: req
  });
}

export interface CheckS3FarmAccessRequest {
  uri: string;
}

export function checkS3FarmAccess(
  authedFetcher: FetcherFunc,
  req: CheckS3FarmAccessRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/check_s3_farm_access',
    json_body: req
  });
}

export interface CheckS3SampleAccessRequest {
  uri: string;
}

export function checkS3SampleAccess(
  authedFetcher: FetcherFunc,
  req: CheckS3SampleAccessRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/check_s3_sample_access',
    json_body: req
  });
}

export interface CheckS3VisitAccessRequest {
  uri: string;
}

export function checkS3VisitAccess(
  authedFetcher: FetcherFunc,
  req: CheckS3VisitAccessRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/check_s3_visit_access',
    json_body: req
  });
}

export interface DeleteClaimRequest {
  claim_id: Uuid;
}

export function deleteClaim(
  authedFetcher: FetcherFunc,
  req: DeleteClaimRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/delete_claim',
    json_body: req
  });
}

export interface DeleteFarmRequest {
  farm_id: Uuid;
}

export function deleteFarm(
  authedFetcher: FetcherFunc,
  req: DeleteFarmRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/delete_farm',
    json_body: req
  });
}

export interface DeleteFieldRequest {
  field_id: Uuid;
}

export function deleteField(
  authedFetcher: FetcherFunc,
  req: DeleteFieldRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/delete_field',
    json_body: req
  });
}

export interface DeleteFieldLayersRequest {
  field_id: Uuid;
}

export function deleteFieldLayers(
  authedFetcher: FetcherFunc,
  req: DeleteFieldLayersRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/delete_field_layers',
    json_body: req
  });
}

export interface DeleteHarvestRequest {
  harvest_id: Uuid;
}

export function deleteHarvest(
  authedFetcher: FetcherFunc,
  req: DeleteHarvestRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/delete_harvest',
    json_body: req
  });
}

export interface DeletePolicyRequest {
  policy_id: Uuid;
}

export function deletePolicy(
  authedFetcher: FetcherFunc,
  req: DeletePolicyRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/delete_policy',
    json_body: req
  });
}

export interface DeletePredictedYieldRequest {
  harvest_id: Uuid;
}

export function deletePredictedYield(
  authedFetcher: FetcherFunc,
  req: DeletePredictedYieldRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/delete_predicted_yield',
    json_body: req
  });
}

export interface DeleteSampleRequest {
  sample_id: Uuid;
}

export function deleteSample(
  authedFetcher: FetcherFunc,
  req: DeleteSampleRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/delete_sample',
    json_body: req
  });
}

export interface DeleteVisitRequest {
  visit_id: Uuid;
}

export function deleteVisit(
  authedFetcher: FetcherFunc,
  req: DeleteVisitRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/delete_visit',
    json_body: req
  });
}

export interface FinishLayerPackage3Request {
  max_updated_at: string;
  identifiers: (string|null)[];
  offsets: (number|null)[];
  bundles: string;
}

export function finishLayerPackage3(
  authedFetcher: FetcherFunc,
  req: FinishLayerPackage3Request,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/finish_layer_package3',
    json_body: req
  });
}

export interface GetClaimRowsRequest {
  ordering: string;
  row_count: number;
  f?: Filters|null;
  continue_from_val?: string|null;
  continue_from_id?: Uuid|null;
}

export interface GetClaimRowsRow {
  claim: Claim|null;
  farm: Farm|null;
  policy: Policy|null;
}

export function getClaimRows(
  authedFetcher: FetcherFunc,
  req: GetClaimRowsRequest,
  useApi2 = false,
): Promise<GetClaimRowsRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_claim_rows',
    json_body: req
  }).then(    (resp: any[]) => resp.map(x => ({
      ...x,
      farm: x.farm ? transportToFarm(x.farm) : null,
      policy: x.policy ? transportToPolicy(x.policy) : null,
    }))
  );
}

export interface GetEntityFeatures3Request {
  n?: number|null;
  e?: number|null;
  s?: number|null;
  w?: number|null;
  with_shapes?: boolean|null;
  f?: Filters|null;
}

export function getEntityFeatures3(
  authedFetcher: FetcherFunc,
  req: GetEntityFeatures3Request,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_entity_features3',
    json_body: req
  });
}

export interface GetFarmHarvestRowsRequest {
  ordering: string;
  row_count: number;
  f?: Filters|null;
  continue_from_val?: string|null;
  continue_from_id?: Uuid|null;
}

export interface GetFarmHarvestRowsRow {
  crop_id: string|null;
  harvest_year: HarvestYear|null;
  farm: Farm|null;
  harvest_data: (HarvestData|null)[]|null;
  farm_override_sample: Sample|null;
}

export function getFarmHarvestRows(
  authedFetcher: FetcherFunc,
  req: GetFarmHarvestRowsRequest,
  useApi2 = false,
): Promise<GetFarmHarvestRowsRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_farm_harvest_rows',
    json_body: req
  }).then(    (resp: any[]) => resp.map(x => ({
      ...x,
      farm: x.farm ? transportToFarm(x.farm) : null,
      farm_override_sample: x.farm_override_sample ? transportToSample(x.farm_override_sample) : null,
    }))
  );
}

export interface GetFarmReportDbRequest {
  farm_id: Uuid;
  harvest_year: HarvestYear;
  crop_ids: (string|null)[];
}

export function getFarmReportDb(
  authedFetcher: FetcherFunc,
  req: GetFarmReportDbRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_farm_report_db',
    json_body: req
  });
}

export interface GetFarmReportInterfieldRequest {
  farm_id: Uuid;
  harvest_year: HarvestYear;
  crop_ids: (string|null)[];
}

export function getFarmReportInterfield(
  authedFetcher: FetcherFunc,
  req: GetFarmReportInterfieldRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_farm_report_interfield',
    json_body: req
  });
}

export interface GetFarmRowsRequest {
  ordering: string;
  row_count: number;
  f?: Filters|null;
  continue_from_val?: string|null;
  continue_from_id?: Uuid|null;
}

export interface GetFarmRowsRow {
  farm: Farm|null;
  farm_crop_ids: (string|null)[]|null;
  num_fields: number|null;
  num_samples: number|null;
  total_area_ha: number|null;
}

export function getFarmRows(
  authedFetcher: FetcherFunc,
  req: GetFarmRowsRequest,
  useApi2 = false,
): Promise<GetFarmRowsRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_farm_rows',
    json_body: req
  }).then(    (resp: any[]) => resp.map(x => ({
      ...x,
      farm: x.farm ? transportToFarm(x.farm) : null,
    }))
  );
}

export interface GetFieldRowsRequest {
  ordering: string;
  row_count: number;
  f?: Filters|null;
  continue_from_val?: string|null;
  continue_from_id?: Uuid|null;
}

export interface GetFieldRowsRow {
  farm: Farm|null;
  field: Field|null;
  harvest: Harvest|null;
  harvest_data: HarvestData|null;
  region_info: (RegionInfo|null)[]|null;
}

export function getFieldRows(
  authedFetcher: FetcherFunc,
  req: GetFieldRowsRequest,
  useApi2 = false,
): Promise<GetFieldRowsRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_field_rows',
    json_body: req
  }).then(    (resp: any[]) => resp.map(x => ({
      ...x,
      farm: x.farm ? transportToFarm(x.farm) : null,
      field: x.field ? transportToField(x.field) : null,
      harvest: x.harvest ? transportToHarvest(x.harvest) : null,
      harvest_data: x.harvest_data ? transportToHarvestData(x.harvest_data) : null,
    }))
  );
}

export interface GetFieldScoringRowsRequest {
  ordering: string;
  row_count: number;
  f?: Filters|null;
  continue_from_val?: string|null;
  continue_from_id?: Uuid|null;
}

export interface GetFieldScoringRowsRow {
  farm: Farm|null;
  field: Field|null;
  field_data: (FieldData|null)[]|null;
  field_series_data: (FieldSeriesData|null)[]|null;
}

export function getFieldScoringRows(
  authedFetcher: FetcherFunc,
  req: GetFieldScoringRowsRequest,
  useApi2 = false,
): Promise<GetFieldScoringRowsRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_field_scoring_rows',
    json_body: req
  }).then(    (resp: any[]) => resp.map(x => ({
      ...x,
      farm: x.farm ? transportToFarm(x.farm) : null,
      field: x.field ? transportToField(x.field) : null,
    }))
  );
}

export interface GetFieldSeriesRequest {
  _field_ids?: (string|null)[]|null;
  _farm_ids?: (string|null)[]|null;
  _harvest_year?: HarvestYear|null;
  _by_harvest?: boolean;
  _min_updated_at?: string|null;
}

export interface GetFieldSeriesRow {
  farm_id: Uuid|null;
  field_id: Uuid|null;
  harvest_id: Uuid|null;
  harvest_year: HarvestYear|null;
  emergence_date: string|null;
  max_vi_date: string|null;
  series: any /* TODO(autogen): json,pg_catalog,json */|null;
  max_updated_at: string|null;
}

export function getFieldSeries(
  authedFetcher: FetcherFunc,
  req: GetFieldSeriesRequest,
  useApi2 = false,
): Promise<GetFieldSeriesRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_field_series',
    json_body: req
  });
}

export interface GetFieldShapesRequest {
  n: number;
  e: number;
  s: number;
  w: number;
}

export function getFieldShapes(
  authedFetcher: FetcherFunc,
  req: GetFieldShapesRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_field_shapes',
    json_body: req
  });
}

export interface GetLayerPackage3Request {
  layer_type: string;
  canonical_date: string;
  min_updated_at?: string|null;
  region?: string|null;
  field_ids?: string|null;
}

export function getLayerPackage3(
  authedFetcher: FetcherFunc,
  req: GetLayerPackage3Request,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_layer_package3',
    json_body: req
  });
}

export interface GetMapStatsRequest {
  f?: Filters|null;
}

export interface GetMapStatsRow {
  region_id: string|null;
  crop_id: string|null;
  harvest_year: HarvestYear|null;
  num_samples: number|null;
  weighted_estimated_yield_t_ha: number|null;
  straight_estimated_yield_t_ha: number|null;
  num_fields: number|null;
  total_area_ha: number|null;
}

export function getMapStats(
  authedFetcher: FetcherFunc,
  req: GetMapStatsRequest,
  useApi2 = false,
): Promise<GetMapStatsRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_map_stats',
    json_body: req
  });
}

export interface GetPacketRequest {
  props: any /* TODO(autogen): json,pg_catalog,json */;
  bin: string;
}

export function getPacket(
  authedFetcher: FetcherFunc,
  req: GetPacketRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_packet',
    json_body: req
  });
}

export interface GetPolicyRowsRequest {
  ordering: string;
  row_count: number;
  f?: Filters|null;
  continue_from_val?: string|null;
  continue_from_id?: Uuid|null;
}

export interface GetPolicyRowsRow {
  policy: Policy|null;
  harvest_data: (HarvestData|null)[]|null;
}

export function getPolicyRows(
  authedFetcher: FetcherFunc,
  req: GetPolicyRowsRequest,
  useApi2 = false,
): Promise<GetPolicyRowsRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_policy_rows',
    json_body: req
  }).then(    (resp: any[]) => resp.map(x => ({
      ...x,
      policy: x.policy ? transportToPolicy(x.policy) : null,
    }))
  );
}

export interface GetPortfolioStatsRequest {
  region_level: RegionLevel;
  f?: Filters|null;
}

export interface GetPortfolioStatsRow {
  region_id: string|null;
  region_name: string|null;
  region_info: (RegionInfo|null)[]|null;
  crop_id: string|null;
  yield_area: number|null;
  insured_area: number|null;
  insured_production: number|null;
  estimated_yield: number|null;
  total_loss: number|null;
}

export function getPortfolioStats(
  authedFetcher: FetcherFunc,
  req: GetPortfolioStatsRequest,
  useApi2 = false,
): Promise<GetPortfolioStatsRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_portfolio_stats',
    json_body: req
  });
}

export interface GetSampleCountsByAdjusterRequest {
  f?: Filters|null;
}

export interface GetSampleCountsByAdjusterRow {
  added_by: string|null;
  sample_date: string|null;
  count: number|null;
}

export function getSampleCountsByAdjuster(
  authedFetcher: FetcherFunc,
  req: GetSampleCountsByAdjusterRequest,
  useApi2 = false,
): Promise<GetSampleCountsByAdjusterRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_sample_counts_by_adjuster',
    json_body: req
  });
}

export interface GetSampleRowsRequest {
  ordering: string;
  row_count: number;
  f?: Filters|null;
  continue_from_val?: string|null;
  continue_from_id?: Uuid|null;
}

export interface GetSampleRowsRow {
  farm: Farm|null;
  field: Field|null;
  harvest: Harvest|null;
  sample: Sample|null;
  policy: Policy|null;
  region_info: (RegionInfo|null)[]|null;
}

export function getSampleRows(
  authedFetcher: FetcherFunc,
  req: GetSampleRowsRequest,
  useApi2 = false,
): Promise<GetSampleRowsRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_sample_rows',
    json_body: req
  }).then(    (resp: any[]) => resp.map(x => ({
      ...x,
      farm: x.farm ? transportToFarm(x.farm) : null,
      field: x.field ? transportToField(x.field) : null,
      harvest: x.harvest ? transportToHarvest(x.harvest) : null,
      sample: x.sample ? transportToSample(x.sample) : null,
      policy: x.policy ? transportToPolicy(x.policy) : null,
    }))
  );
}

export interface GetStatsByCropCondRequest {
  f?: Filters|null;
}

export interface GetStatsByCropCondRow {
  condition: CropCondition|null;
  area_ha: number|null;
}

export function getStatsByCropCond(
  authedFetcher: FetcherFunc,
  req: GetStatsByCropCondRequest,
  useApi2 = false,
): Promise<GetStatsByCropCondRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_stats_by_crop_cond',
    json_body: req
  });
}

export interface GetStatsByHarvestRequest {
  f?: Filters|null;
}

export interface GetStatsByHarvestRow {
  crop_id: string|null;
  harvest_year: HarvestYear|null;
  num_samples: number|null;
  estimated_yield_t_ha: number|null;
  num_fields: number|null;
  total_area_ha: number|null;
}

export function getStatsByHarvest(
  authedFetcher: FetcherFunc,
  req: GetStatsByHarvestRequest,
  useApi2 = false,
): Promise<GetStatsByHarvestRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_stats_by_harvest',
    json_body: req
  });
}

export interface GetVisitRowsRequest {
  ordering: string;
  row_count: number;
  f?: Filters|null;
  continue_from_val?: string|null;
  continue_from_id?: Uuid|null;
  closed_state?: boolean|null;
}

export interface GetVisitRowsRow {
  visit: VisitLite|null;
  farm: Farm|null;
  policy: Policy|null;
  claim: Claim|null;
  claim_damage: (ClaimDamage|null)[]|null;
}

export function getVisitRows(
  authedFetcher: FetcherFunc,
  req: GetVisitRowsRequest,
  useApi2 = false,
): Promise<GetVisitRowsRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/get_visit_rows',
    json_body: req
  }).then(    (resp: any[]) => resp.map(x => ({
      ...x,
      visit: x.visit ? transportToVisitLite(x.visit) : null,
      farm: x.farm ? transportToFarm(x.farm) : null,
      policy: x.policy ? transportToPolicy(x.policy) : null,
    }))
  );
}

export interface GrantUserAccessRequest {
  param_email: string;
  param_user_group: string;
  param_membership_type: MembershipType;
}

export function grantUserAccess(
  authedFetcher: FetcherFunc,
  req: GrantUserAccessRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/grant_user_access',
    json_body: req
  });
}

export interface InsertApiEntitiesRequest {
  rel: string;
  arr: any /* TODO(autogen): json,pg_catalog,json */;
}

export function insertApiEntities(
  authedFetcher: FetcherFunc,
  req: InsertApiEntitiesRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/insert_api_entities',
    json_body: req
  });
}

export interface InsertManyRequest {
  param: any /* TODO(autogen): json,pg_catalog,json */;
}

export function insertMany(
  authedFetcher: FetcherFunc,
  req: InsertManyRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/insert_many',
    json_body: req
  });
}

export interface MergeFarmsRequest {
  farm_ids: (string|null)[];
}

export function mergeFarms(
  authedFetcher: FetcherFunc,
  req: MergeFarmsRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/merge_farms',
    json_body: req
  });
}

export interface MergeFieldsRequest {
  field_ids: (string|null)[];
}

export function mergeFields(
  authedFetcher: FetcherFunc,
  req: MergeFieldsRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/merge_fields',
    json_body: req
  });
}

export interface MergeHarvestsRequest {
  harvest_ids: (string|null)[];
}

export function mergeHarvests(
  authedFetcher: FetcherFunc,
  req: MergeHarvestsRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/merge_harvests',
    json_body: req
  });
}

export interface MergePoliciesRequest {
  policy_ids: (string|null)[];
}

export function mergePolicies(
  authedFetcher: FetcherFunc,
  req: MergePoliciesRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/merge_policies',
    json_body: req
  });
}

export interface RevokeUserAccessRequest {
  param_email: string;
  param_user_group: string;
  param_membership_type: MembershipType;
}

export function revokeUserAccess(
  authedFetcher: FetcherFunc,
  req: RevokeUserAccessRequest,
  useApi2 = false,
): Promise<void> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/revoke_user_access',
    json_body: req
  });
}

export interface SearchFarmsRequest {
  query: string;
}

export interface SearchFarmsRow {
  farm_id: Uuid|null;
  farm_name: string|null;
  address: string|null;
  external_farm_id: string|null;
  user_group: string|null;
}

export function searchFarms(
  authedFetcher: FetcherFunc,
  req: SearchFarmsRequest,
  useApi2 = false,
): Promise<SearchFarmsRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/search_farms',
    json_body: req
  });
}

export interface SearchPoliciesRequest {
  query: string;
}

export interface SearchPoliciesRow {
  policy_id: Uuid|null;
  policy_number: string|null;
  user_group: string|null;
}

export function searchPolicies(
  authedFetcher: FetcherFunc,
  req: SearchPoliciesRequest,
  useApi2 = false,
): Promise<SearchPoliciesRow[]> {
  return authedFetcher({
    method: 'POST',
    path: (useApi2 ? 'api2' : 'api') + '/rpc/search_policies',
    json_body: req
  });
}
