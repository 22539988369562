import {History, createBrowserHistory} from 'history';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {Clock} from '../../src/Clock';
import {baseFetcher, createAuthedFetcher} from '../../src/FetcherFunc';
import {initI18n} from '../../src/i18n/i18n-util';
import {
  FarmReportPackage,
  PortfolioReportPackage,
  TelepacReportMultiPackage,
  TelepacReportPackage,
} from '../../src/report/report-types';
import {getCustomers} from '../../src/util/user-group-util';
import {authFromAltToken, authFromKeycloak} from './apis/auth';
import {createApis} from './apis/createApis';
import {getLocale} from './apis/i18n';
import {initLogging} from './apis/logging';
import './index.css';
import {StandaloneReport} from './report/StandaloneReport';
import {App} from './routers/App';
import {reportErr} from './util/err';

const history: History = createBrowserHistory();

async function main() {
  // Initialize logging (without an email) to catch any errors during initialization. It will be re-initialized later
  // with an email.
  initLogging(history);

  try {
    const reportPackage = (global as any)['reportPackage'] as
      | TelepacReportPackage
      | FarmReportPackage
      | TelepacReportMultiPackage
      | PortfolioReportPackage;

    if (!reportPackage) {
      const auth = await authFromKeycloak();
      await initLogging(history, auth.getUser().email);
      const apis = await createApis(auth);
      const container = document.getElementById('root');
      const root = createRoot(container!);
      root.render(<App apis={apis} history={history} />);
    } else {
      initLogging(history, 'standalone-reports');
      const altToken = (global as any)['altToken'] as null | string;
      const auth = altToken ? authFromAltToken(altToken) : null;
      const clock = new Clock();
      const authedFetcher =
        auth && createAuthedFetcher(auth.getAuthToken, baseFetcher.bind(null, window.location.origin, clock));
      const userGroups = authedFetcher ? await authedFetcher({method: 'GET', path: 'api/user_group'}) : [];
      const t = initI18n(getLocale(auth ?? null), getCustomers(auth?.getUser().email, userGroups));
      const root = createRoot(document.getElementById('root')!);
      root.render(
        <StandaloneReport package={reportPackage} clock={clock} t={t} authedFetcher={authedFetcher} fetchFn={fetch} />,
      );
    }
  } catch (e) {
    reportErr(e, 'index-tsx');
  }
}

main();
