import React, {useCallback, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {FormyChanged, FormyErrors, FormyI, keepChangedFields} from '../../../src/Formy';
import {useFormy, useFormyValue} from '../../../src/Formy/hooks';
import {FarmForm, validateFarmForm} from '../../../src/forms/FarmForm';
import {updateFarm} from '../../../src/redux/actions/db';
import {fetchEntities} from '../../../src/util/fetchEntity';
import {FormyAddress} from '../Formy/FormyAddress';
import FormyEditorsSelector from '../Formy/FormyEditorsSelector';
import {FormySubmit} from '../Formy/FormySubmit';
import {FormySuggest} from '../Formy/FormySuggest';
import FormyUserGroupSelector from '../Formy/FormyUserGroupSelector';
import {useApis} from '../apis/ApisContext';
import {reportErr} from '../util/err';
import {commitMutations} from './commit';

function validateFarmForms(x: FarmForms): FormyErrors<FarmForms> {
  const res: FormyErrors<{[farm_id: string]: FarmForm}> = {};
  for (const farm_id in x.farms) {
    res[farm_id] = validateFarmForm(x.farms[farm_id]);
  }
  return {farms: res};
}

class FarmForms {
  farms: {[farm_id: string]: FarmForm} = {};
}

interface FarmRowsProps {
  formy: FormyI<FarmForms>;
  field: 'farms';
}

const FarmRows: React.FC<FarmRowsProps> = ({formy}) => {
  const {store} = useApis();
  const farms = useFormyValue(formy, 'farms');
  const farm_ids = Object.keys(farms);
  return (
    <span className="import-form">
      <span>
        <span className="import-row header">
          <span className="import-cell">{formy.t('UserGroup')}</span>
          <span className="import-cell">{formy.t('FarmReference')}</span>
          <span className="import-cell">{formy.t('FarmName')}</span>
          <span className="import-cell">{formy.t('FarmAddress')}</span>
          <span className="import-cell">{formy.t('Editors')}</span>
        </span>
        {farm_ids.map(farm_id => {
          const farmFormy = formy.getSectionFormy('farms').getSectionFormy(farm_id);
          return (
            <span className="import-row" key={farm_id}>
              <span className="import-cell">
                <FormyUserGroupSelector formy={farmFormy} field="user_group" />
              </span>
              <span className="import-cell">
                <FormySuggest
                  onEntitySelected={null}
                  label={'FarmReference'}
                  field="external_farm_id"
                  formy={farmFormy}
                />
              </span>
              <span className="import-cell">
                <FormySuggest onEntitySelected={null} label="FarmName" field="farm_name" formy={farmFormy} />
              </span>
              <span className="import-cell">
                <FormyAddress formy={farmFormy} />
              </span>
              <span className="import-cell">
                <FormyEditorsSelector formy={farmFormy} />
              </span>
            </span>
          );
        })}
      </span>
    </span>
  );
};

export default function EditFarms() {
  const apis = useApis(),
    location = useLocation(),
    history = useHistory();
  const farm_ids = useMemo(() => new URLSearchParams(location.search).get('farm_ids')?.split(',') ?? [], [location]);
  const onSubmit = useCallback(
    async (values: FarmForms, changedCols: FormyChanged<FarmForms>) => {
      try {
        const changedFarms =
          typeof changedCols == 'object' && typeof changedCols.farms == 'object' ? changedCols.farms : {};
        for (const farm_id in values.farms) {
          const changed = keepChangedFields(values.farms[farm_id], changedFarms[farm_id]);
          if (changed) {
            apis.store.dispatch(updateFarm(apis, farm_id, changed));
          }
        }

        if (await commitMutations(apis)) {
          history.goBack();
        }
      } catch (e) {
        reportErr(e, 'EditFarms::onSubmit');
      }
    },
    [apis, history],
  );

  const formy = useFormy(
    'edit',
    async () => {
      const initialValues: FarmForms = new FarmForms();
      const farms = await fetchEntities(apis.authedFetcher, 'farm', farm_ids);
      for (const farm of farms) {
        initialValues.farms[farm.farm_id] = new FarmForm(farm, null, null);
      }
      return initialValues;
    },
    apis.t,
    onSubmit,
    validateFarmForms,
    farm_ids.join('_'),
  );

  if (!formy) {
    return null;
  }

  return (
    <span>
      <FarmRows formy={formy} field="farms" />
      <span className="import-save">
        <FormySubmit label="Save" formy={formy} doNotConfirmSubmissionToUser={true} />
      </span>
    </span>
  );
}
