import {UserGroupCustomer} from '../util/user-group-util';
import type {I18nFunction, Locale} from './i18n';
import {getBaseLanguages} from './lang-util';
import t_de from './t-de';
import t_en from './t-en';
import t_en_US from './t-en-US';
import t_es from './t-es';
import t_fr from './t-fr';
import t_fr__groupama from './t-fr--groupama';
import t_it from './t-it';
import t_pl from './t-pl';
import t_pt from './t-pt';
import t_ro from './t-ro';

export function intl_num(v: null | number) {
  if (typeof v !== 'number') {
    return '-';
  }
  if (Math.abs(v) < 0.001) {
    // avoids displaying 0.00 for very small numbers
    return v.toFixed(1);
  } else if (Math.abs(v) < 1) {
    return v.toFixed(2);
  } else {
    return v.toFixed(1);
  }
}

export function intl_num_2(v: null | number) {
  if (typeof v !== 'number') {
    return '-';
  }
  return v.toFixed(2);
}

export function intl_num_4(v: null | number) {
  if (typeof v !== 'number') {
    return '-';
  }
  return v.toFixed(4);
}

export function intl_int(v: null | number) {
  if (typeof v !== 'number') {
    return '-';
  }
  return String(Math.round(v));
}

const Msgs: {[P in Locale]: I18nFunction} = {
  de: t_de,
  en: t_en,
  'en-US': t_en_US,
  es: t_es,
  fr: t_fr,
  it: t_it,
  pl: t_pl,
  pt: t_pt,
  ro: t_ro,
  'fr--groupama': t_fr__groupama,
};

// Take any locale and return the closest matching locale for which we have a translation file at hand.
export function resolveLocale(locale: string | null): Locale {
  if (isAvailableLocale(locale)) {
    return locale;
  }
  const baseLanguages = getBaseLanguages(locale);
  for (const baseLanguage of baseLanguages) {
    if (isAvailableLocale(baseLanguage)) {
      return baseLanguage;
    }
  }
  return 'en';
}

export function initI18n(locale: null | string, customers?: Set<UserGroupCustomer>): I18nFunction {
  if (locale && customers?.size === 1) {
    const customer = customers.values().next().value;
    const baseLanguages = getBaseLanguages(locale);
    // Base languages will always return a smaller subset of the locale without the locale itself.
    // We manually include it the search (e.g. if locale is en-US, base languages will contain 'en',
    // and we want to first try to match by en-US-<user_group> and then en--<user_group>).
    baseLanguages.unshift(locale);
    for (const baseLanguage of baseLanguages) {
      const locale = `${baseLanguage}${baseLanguage.includes('-') ? '-' : '--'}${customer}`;
      if (isAvailableLocale(locale)) {
        return Msgs[locale];
      }
    }
  }
  return Msgs[resolveLocale(locale)];
}

export function isAvailableLocale(locale: string | null): locale is Locale {
  return !!Msgs[locale as Locale];
}

// We may receive any of these locale strings: https://gist.github.com/ndbroadbent/588fefab8e0f1b459fcec8181b41b39c
// To prevent errors at other places in the code-base, with non-normalized locales, we normalize them here.
export function normalizeLocale(locale: string | undefined): string {
  if (!locale) {
    return 'en';
  }
  const normalized = locale.replace('_', '-');
  if (!/^[a-z]{2}(-[A-Z]{2})$/.test(normalized)) {
    // Try falling back to the first 2 characters, or return undefined if that fails.
    if (/^[a-z]{2}-/.test(normalized)) {
      return normalized.slice(0, 2);
    }
    return 'en';
  }
  return normalized;
}
