import {DebounceSettings, ThrottleSettings, debounce, throttle} from 'lodash';
import {DependencyList, useCallback, useEffect, useRef, useState} from 'react';
import {CommonApis} from '../CommonApis';

// useAsyncMemo returns a memoized value from an asynchronous factory function.
// It will call factory only if the deps change; and it will only ever return a value if it corresponds to the current
// deps. This will happen synchronously: if the deps change, useAsyncMemo will immediately return undefined,
// until `factory` has returned a new value.
export function useAsyncMemo<T>(factory: () => Promise<T>, deps: DependencyList): T | undefined {
  const [val, setVal] = useState<[T, DependencyList] | undefined>();
  const counter = useRef(0);

  useEffect(() => {
    // Increment counter inside useEffect, right before we make a new request. Otherwise,
    // the counter be incremented without a new request being made, and a valid response would be discarded.
    counter.current++;
    const curCounter = counter.current;
    factory()
      .then(x => {
        if (counter.current == curCounter) {
          // Keep val only if it was the latest call to factory().
          setVal([x, deps]);
        }
      })
      .catch(e => {
        console.error('useAsyncMemo:', e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return val && val[0];
}

export function useThrottledCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList,
  wait: number,
  opts?: ThrottleSettings,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(throttle(callback, wait, opts), deps);
}

export function useDebouncedCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList,
  wait: number,
  opts?: DebounceSettings,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(callback, wait, opts), deps);
}

export function useDebounce<T>(clock: CommonApis['clock'], value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = clock.setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clock.clearTimeout(handler);
    };
  }, [value, delay, clock]);

  return debouncedValue;
}
