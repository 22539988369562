// @formatter:off
import {intl_int, intl_num, intl_num_2, intl_num_4} from './i18n-util';
import {I18nParametric, I18nSimpleKey} from './i18n';

const msgs = {
  '1990': '1990',
  '1991': '1991',
  '1992': '1992',
  '1993': '1993',
  '1994': '1994',
  '1995': '1995',
  '1996': '1996',
  '1997': '1997',
  '1998': '1998',
  '1999': '1999',
  '2000': '2000',
  '2001': '2001',
  '2002': '2002',
  '2003': '2003',
  '2004': '2004',
  '2005': '2005',
  '2006': '2006',
  '2007': '2007',
  '2008': '2008',
  '2009': '2009',
  '2010': '2010',
  '2011': '2011',
  '2012': '2012',
  '2013': '2013',
  '2014': '2014',
  '2015': '2015',
  '2016': '2016',
  '2017': '2017',
  '2018': '2018',
  '2019': '2019',
  '2020': '2020',
  '2021': '2021',
  '2022': '2022',
  '2023': '2023',
  '2024': '2024',
  '2025': '2025',
  '2026': '2026',
  '2027': '2027',
  '2028': '2028',
  '2029': '2029',
  '2030': '2030',
  '2031': '2031',
  '2032': '2032',
  '2033': '2033',
  '2034': '2034',
  '2035': '2035',
  'ARG': 'Argentinien',
  'AUS': 'Australien',
  'AUT': 'Österreich',
  'Accept': 'Akzeptieren',
  'AcceptOrDecline': 'Akzeptieren/ablehnen',
  'AddAnotherArea': 'Ein weiteres Gebiet hinzufügen',
  'AddAssignee': 'Beauftragten hinzufügen',
  'AddAttachments': 'Anhänge hinzufügen',
  'AddCause': 'Eine Ursache hinzufügen',
  'AddClaim': 'Anspruch hinzufügen',
  'AddCost': 'Kosten hinzufügen',
  'AddCrop': 'Erntetyp hinzufügen',
  'AddEditSampleShape': 'Betroffenen Bereich auf der Karte einzeichnen',
  'AddEditor': 'Editor hinzufügen',
  'AddEditors': 'Editoren hinzufügen',
  'AddEditorsOneGroup': 'Bitte fügen Sie Bearbeiter nur einem einzelnen Portfolio hinzu',
  'AddFarm': 'Betrieb hinzufügen',
  'AddFarmInfo': 'Um neue Felder zu einer bestehenden Farm hinzuzufügen, müssen sie zunächst die Farm über die Felder "Name" oder "Referenz" auswählen.',
  'AddField': 'Feld hinzufügen',
  'AddFieldShape': 'Feldgrenzen hinzufügen',
  'AddFieldShapes': 'Feldgrenzen hinzufügen',
  'AddFieldsHarvests': 'Felder und Ernten hinzufügen',
  'AddFromFileManager': 'Aus Dateien hinzufügen',
  'AddFromPhotos': 'Aus Fotos hinzufügen',
  'AddGuaranteedLossCause': 'Fügen Sie eine versicherte Schadensursache hinzu',
  'AddHarvest': 'Neue Ernte hinzufügen',
  'AddLossCause': 'Eine Verlustursache hinzufügen',
  'AddNewDamage': 'Neuen Schaden hinzufügen',
  'AddNewHarvest': 'Neue Ernte hinzufügen',
  'AddNewHarvestFor': 'Legen Sie ein neues Erntejahr an',
  'AddNewLoss': 'Neuen Verlust hinzufügen',
  'AddNewSubparcel': 'Neues Teilstück hinzufügen',
  'AddOrEditFields': 'Felder hinzufügen/bearbeiten',
  'AddPhoto': 'Foto hinzufügen',
  'AddPhotoError': 'Das Fotografieren ist fehlgeschlagen',
  'AddPolicy': 'Neuen Vertrag hinzufügen',
  'AddRecipient': 'Empfänger hinzufügen',
  'AddSample': 'Probe hinzufügen',
  'AddSamplePhoto': 'Foto zur Probe hinzufügen',
  'AddSubplotId': 'Teilstück hinzufügen',
  'AddUnguaranteedLossCause': 'Fügen Sie eine nicht garantierte Schadensursache hinzu',
  'AddUser': 'Benutzer hinzufügen',
  'AddVarietyHarvest': 'Für Abwechslung sorgen',
  'AddedBy': 'Hinzugefügt von',
  'AddedGroups': 'Gruppen hinzugefügt',
  'AddedOn': 'Hinzugefügt am',
  'AdditionalInfo': 'Zusätzliche Information',
  'AdditionalQuestions': 'Weitere Fragen',
  'AdvancedOptions': 'Erweiterte Optionen',
  'AffectedArea': 'Betroffener Bereich',
  'AffectedCrop': 'Betroffener Kulturtyp',
  'AffectedCrops': 'Betroffene Kulturtypen',
  'AffectedCropsRequiredToDeclareLoss': 'Sie müssen mindestens eine betroffene Kulturpflanze auswählen, um die gemeldeten Verluste hinzuzufügen.',
  'AffectedField': 'Betroffenes Feld',
  'All': 'Alle',
  'AllSynced': 'Alles synchronisiert',
  'AllTime': 'Gesamtzeit',
  'AlsoInheritedFrom': 'Auch geerbt von',
  'Amount': 'Betrag (€)',
  'AppVersionDeprecated': 'App-Version veraltet',
  'AppVersionDeprecatedMessage': 'Ihre App-Version ist veraltet. Sie müssen die Seite aktualisieren, um die neue Version zu erhalten. Klicken Sie auf AKTUALISIEREN, um die Seite zu aktualisieren. Wenn Sie über nicht gespeicherte Daten verfügen, klicken Sie auf „Abbrechen“, sichern Sie die Daten manuell und aktualisieren Sie dann die Seite.',
  'Area': 'Bereich',
  'AreaCropTitle': 'Fläche nach Kulturtyp',
  'Ascending': 'aufsteigend',
  'Assign': 'Zuordnen',
  'AssignExistingFields': 'Bestehende Felder zuweisen',
  'AssignedLossAdjusters': 'Zugewiesene Schadenregulierer',
  'AssignedTo': 'Zugewiesen an',
  'AssociatedEmails': 'Zugehörige E-Mails',
  'Attachment': 'Anhang',
  'Attachments': 'Anhänge',
  'Average': 'Durchschnitt',
  'AverageFieldArea': 'Durchschnittliche Feldfläche',
  'AvgBollsPerPlant': 'Durchschnittliche Anzahl an Samenkapseln pro Pflanze',
  'AvgEarsPerPlant': 'Durchschnittliche Anzahl von Ähren pro Pflanze',
  'AvgGrainsPerEar': 'Durchschnittliche Anzahl von Körnern pro Ähre',
  'AvgGrainsPerPod': 'Durchschnittliche Anzahl von Körnern pro Schote',
  'AvgGrainsPerSilique': 'Durchschnittliche Anzahl von Körnern pro Schote',
  'AvgKernelsPerBoll': 'Durchschnittliche Anzahl Kerne pro Samenkapsel',
  'AvgPodsPerShaft': 'Durchschnittliche Anzahl von Schoten pro Trieb',
  'AvgSiliquesPerShaft': 'Durchschnittliche Anzahl von Schoten pro Trieb',
  'BEL': 'Belgien',
  'BRA': 'Brasilien',
  'Back': 'Zurück',
  'BackToHome': 'Zurück zur Startseite',
  'Benchmark': 'Referenz',
  'Biomass': 'Biomasse',
  'BunchWeight': 'Durchschnittliches Traubengewicht',
  'BunchesPerPlant': 'Durchschnittliche Traubenanzahl pro Pflanze',
  'ByCrop': 'Nach Ernte',
  'ByField': 'Nach Feld',
  'ByFieldArea': 'Nach Feldgebiet',
  'ByFieldCount': 'Nach Feldanzahl',
  'CAN': 'Kanada',
  'CHE': 'Schweiz',
  'CZE': 'Tschechien',
  'Calculate': 'Berechnung',
  'CalculateAbbreviated': 'Berechnen',
  'Cancel': 'Abbrechen',
  'CantSelectTooManyItems': 'Sie können nicht mehr als 50 Elemente auswählen.',
  'Change': 'Veränderung',
  'ChangeOverrideSampleMsg': 'Möchten Sie den Override-Wert für diesen Zuschnitt wirklich ändern?',
  'ChangeUserAccessRights': 'Benutzerzugriffsrechte ändern',
  'CheckForCodepushUpdate': 'Auf Updates prüfen',
  'CirclesArea': 'Kreisgebiet',
  'CirclesDiameter': 'Durchmesser des Kreises',
  'CirclesRadius': 'Radius des Kreises',
  'Claim': 'Schaden',
  'ClaimNumber': 'Schadennummer',
  'ClaimProcessingStartEnd': 'Beginn und Ende der Schadenbearbeitung',
  'ClaimRequests': 'Anspruchsanfragen',
  'ClaimStatus_adjuster-accepted': 'Sachverständiger hat akzeptiert',
  'ClaimStatus_assessment-in-progress': 'Bewertung läuft',
  'ClaimStatus_awaiting-adjuster-acceptance': 'Warten auf die Annahme durch den Schadensregulierer',
  'ClaimStatus_completed': 'Vollendet',
  'ClaimStatus_created-unassigned': 'Erstellt und nicht zugewiesen',
  'ClaimStatus_payment-proposal-sent': 'Zahlungsvorschlag gesendet',
  'ClaimStatus_rejected': 'Abgelehnt',
  'ClaimStatus_visit-date-defined': 'Besuchsdatum definiert',
  'ClaimStatus_visit-report-created': 'Besuchsbericht erstellt',
  'ClaimStatus_visit-samples-collected': 'Der Besuch hat begonnen',
  'Claims': 'Ansprüche',
  'Clear': 'entfernen',
  'ClearAll': 'Alle löschen',
  'Clearing': 'Räumen',
  'ClickHereToAddFarmerEmail': 'Klicken Sie hier, um die E-Mail-Adresse des Landwirts hinzuzufügen',
  'ClickUpload': 'Zum Hochladen klicken',
  'Close': 'Schließen',
  'CloseVisit': 'Schätzung abschliessen',
  'Closed': 'Abgeschlossen',
  'ClosedVisits': 'Abgeschlossene Schadenschätzungen',
  'CloudCover': 'Wolkenbedeckung',
  'CloudCoverInfo': 'Gibt an, ob wegen Wolkenbedeckung (>50%) die Produktivitätsschätzung nicht zuverlässig durchführbar war',
  'CloudCoverWarning': 'Warnung Wolkenbedeckung',
  'CommentInfoWarning': 'Warnung! Erscheint nicht in Berichten.',
  'Comments': 'Kommentare',
  'CompassHeading': 'Kurs',
  'CompensableLosses': 'Erstattungsfähige Verluste',
  'Condition': 'Bedingung',
  'Configuration': 'Konfiguration',
  'Confirm': 'Bestätigen',
  'Contact': 'Kontaktieren Sie uns',
  'ContainsCopernicus': 'Dieses Produkt enthählt geänderte Copernicus Service information (2017)',
  'ContinueWithErrorsAndWarnings': 'Weitermachen, ohne Fehler und Warnungen zu beheben?',
  'ConversionRatio': 'Umrechnungsverhältnis',
  'CopyCurrentHarvest': 'Aktuelle Ernte kopieren',
  'CopyHarvestFromPreviousYears': 'Kopieren Sie die Ernte aus den Vorjahren',
  'CopyLink': 'Link kopieren',
  'CopySample': 'Stichprobe kopieren',
  'CopyVisit': 'Besuch kopieren',
  'CostType': 'Kostenart',
  'Costs': 'Kosten (€)',
  'CouldntDelete': 'Dieses Element konnte nicht gelöscht werden. Zum Löschen müssen Sie online sein.',
  'CreateNewVisit': 'Neuen Besuch erstellen',
  'CropCondition': 'Pflanzenzustand',
  'CropConditionBreakdown': 'Verteilung der Kulturpflanzenbedingungen',
  'CropCoverage': 'Bedeckungsgrad',
  'CropCoverageInfo': 'Zeigt die Bedeckung des Feldes in Prozent.',
  'CropType-s': 'Fruchtart',
  'CropVariety': 'Sorte',
  'CropYieldAverageTitle': 'Geschätzter Durchschnittsertrag',
  'CurHarvest': 'Aktuelle Ernte',
  'CurrentFilters': 'Aktueller Filter',
  'CurrentVisit': 'Aktueller Besuch',
  'CurrentVisits': 'Aktuelle Schadenschätzungen',
  'Customer': 'Kunde',
  'DEU': 'Deutschland',
  'DamagedFields': 'Beschädigte Felder',
  'DamagedHarvest': 'Beschädigte Ernte',
  'Damages': 'Schäden',
  'Dashboard': 'Dashboard',
  'Date': 'Datum',
  'Date-s': 'Datum/Daten',
  'DateRange': 'Datumsbereich',
  'DeadPlantsPct': 'Abgestorbene Pflanzen (%)',
  'DeadVinesPct': 'Abgestorbene Rebstöcke (%)',
  'DeclaredHazard': 'Angegebene Schadensursache',
  'DeclaredLossCauses': 'Gemeldete Schadensursachen',
  'Decline': 'Ablehnen',
  'Deductible': 'Selbstbehalt',
  'Deductible-s': 'Selbstbehalt(e)',
  'Delete': 'Löschen',
  'DeleteForEveryone': 'Für alle löschen',
  'DeleteOfflineArea': 'Offlinebereich löschen',
  'DeleteOverrideSampleMsg': 'Möchten Sie die Überschreibungsschätzung wirklich löschen? Wenn ja, greifen wir auf die Beispiele zurück, um den aggregierten Schätzwert auf Ernteebene abzuleiten.',
  'DeleteUser': 'Benutzer löschen',
  'DeleteUsers': 'Benutzer löschen',
  'Delimiter': 'Trennzeichen',
  'Descending': 'absteigend',
  'DetachedSample-s': 'Nicht zugeordnete Probe(n)',
  'Details': 'Weitere Informationen',
  'DetectDensity': 'Dichte erkennen',
  'DisablePoiMode': 'Alle Betriebe anzeigen',
  'Done': 'Erledigt',
  'Download': 'Herunterladen',
  'DownloadFailed': 'Download fehlgeschlagen',
  'DownloadGeojson': 'GeoJSON-Datei herunterladen',
  'DownloadSuccess': 'Erfolgreich heruntergeladen',
  'DownloadXLSX': 'Excel-Datei herunterladen',
  'Downloading': 'Herunterladen',
  'DownloadingInitialData': 'Herunterladen der Ausgangsdaten',
  'Draw': 'Zeichnen',
  'DuplicateEntryWasRemoved': 'Der folgende Eintrag ist bereits vorhanden und wird nicht importiert:',
  'ESP': 'Spanien',
  'EarDensity': 'Ährendichte',
  'Edit': 'Bearbeiten',
  'EditClaim': 'Anspruch bearbeiten',
  'EditFarm': 'Betriebsinformationen bearbeiten',
  'EditField': 'Feldinformationen bearbeiten',
  'EditFieldShape': 'Feldgrenzen bearbeiten',
  'EditHarvest': 'Ernteinformationen bearbeiten',
  'EditPolicy': 'Vertrag bearbeiten',
  'EditSample': 'Probeinformationen bearbeiten',
  'Editors': 'Editoren',
  'Email': 'E-Mail',
  'EmailMissing': 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
  'Emergence': 'Auflauf',
  'EmergenceDate': 'Aufflaufdatum',
  'EnableNow': 'Jetzt aktivieren',
  'EnablePoiMode': 'Nur Betriebe von Interesse zeigen',
  'EnablePreciseLocation': 'Bitte aktivieren Sie den genauen Standort',
  'EndDate': 'Endtermin',
  'EndDateTime': 'Enddatum und -uhrzeit',
  'Entity': 'Einheit',
  'ErrMissingFields': 'Bitte füllen Sie alle oben unterstrichenen Felder aus.',
  'Error': 'Fehler',
  'EstimatedLossAbbr': 'Voraussichtl. Verlust',
  'EstimatedYield': 'Geschätzter Ertrag',
  'EstimatedYieldAbbr': 'Voraussichtl. Ertrag',
  'EstimatedYieldAreaWeighted': 'Geschätzter Ertrag (flächengewichtet)',
  'EstimatedYieldArithmeticMean': 'Geschätzter Ertrag (arithmetisches Mittel)',
  'EstimatedYieldLoss': 'Geschätzter Ertragsverlust',
  'Estimation': 'Schätzung',
  'EventDate': 'Datum des Ereignisses',
  'EventValidation': 'Ereignisvalidierung',
  'Existing': 'bestehend',
  'ExistingClaim': 'Vorhandener Anspruch',
  'ExistingClaimMsg': 'Für diesen Betrieb besteht bereits ein Anspruch. Möchten Sie mit dem bestehenden Anspruch fortfahren?',
  'ExistingFarm': 'Dieser Betrieb existiert bereits.',
  'ExoBinUnsupported': 'Diese Funktion wird derzeit in dieser Version der App nicht unterstützt. Bitte aktualisieren Sie die App auf die neueste Version.',
  'ExoCamera': 'Exo-Kamera',
  'ExoDetectionError': 'Ähren können nicht erkannt werden. Versuchen Sie es erneut, indem Sie die Position anpassen oder ein neues Bild aufnehmen.',
  'ExoModelNotDownloaded': 'Exo-Modell nicht heruntergeladen. Bitte stellen Sie eine Verbindung zum Internet her, um das Modell für die Offline-Nutzung herunterzuladen.',
  'ExoNotEnabledForUser': 'Diese Funktion ist für Ihr Unternehmen oder Ihre Benutzergruppe nicht aktiviert. Bitte wenden Sie sich an Ihren Administrator.',
  'ExoTutorialPanDescription': 'Bewegen Sie das Bild mit einem Finger.',
  'ExoTutorialPinchDescription': 'Ziehen Sie das Bild zusammen, um es zu vergrößern oder zu verkleinern.',
  'ExoTutorialSearchDescription': 'Richten Sie den Erkennungsbereich auf den gewünschten Teil des Bildes aus, bis er grün wird.',
  'Expert': 'Experte',
  'Expert-s': 'Schadenschätzer',
  'ExpertName': 'Name Schadenschätzer',
  'ExpertRanking': 'Expertenranking',
  'ExpertReport': 'Expertenbericht',
  'ExternalClaimId': 'Externe Anspruchs-ID',
  'ExternalFieldId': 'Externe Feld-ID',
  'ExtremePrecipitation': 'Extreme Niederschläge (>100 mm/Wo.)',
  'ExtremelyHighTemperature': 'Extreme Hitzewelle (Tmax>38°C)',
  'ExtremelyLowTemperature': 'Extremes Frostrisiko (-15°C>Tmin)',
  'FRA': 'Frankreich',
  'FailedToSyncChanges': 'Die Änderungen konnten nicht synchronisiert werden',
  'Farm': 'Betrieb',
  'FarmAddress': 'Betriebsadresse',
  'FarmAndHarvest': 'Info über Landwirtschaftsbetriebe und Ernten',
  'FarmDeletedPleaseStartOver': 'Betrieb wurde gelöscht! Bitte von vorne beginnen',
  'FarmFormComment': 'Zusätzliche Betriebsinformationen',
  'FarmHarvestLevel': 'Betriebsebene',
  'FarmHarvestLevelObservation': 'Beobachtungen für die Ernte',
  'FarmHarvests': 'Fruchtart auf Betriebsebene',
  'FarmInfo': 'Betriebsinformationen',
  'FarmLocationPrompt': 'Drücken Sie fest auf der Karte, um den Standort des Betriebes festzulegen, oder drücken Sie den Pfeil auf der linken Seite, um Ihren Standort zu verwenden.',
  'FarmName': 'Betriebsname',
  'FarmReference': 'Referenz zum Bauernhof',
  'Farmer': 'Versicherungsnehmer',
  'FarmerName': 'Name Versicherter',
  'FarmerOrRepresentative': 'Landwirt oder Vertreter',
  'FarmerRepresentative': 'Vertreter der Landwirte',
  'FarmersEstimatedYieldLoss': 'Geschätzter Ertragsverlust des Landwirts',
  'Farms': 'Betriebe',
  'FeasibleYield': 'Machbarer Ertrag',
  'FeasibleYieldEmpty': 'Der realisierbare Ertrag ist leer.',
  'Feedback': 'Feedback',
  'FetchReportOfflineInfo': 'Sieht so aus, als wären Sie offline, daher kann der Bericht nicht abgerufen werden.',
  'Field': 'Feld',
  'Field-s': 'Feld(er)',
  'FieldArea': 'Fläche',
  'FieldAreaAndReference': 'Fläche und Referenz',
  'FieldCultivatedArea': 'Anbaufläche (Feld)',
  'FieldFormComment': 'Zusätzliche Informationen zur Handlung',
  'FieldHarvests': 'Fruchtart auf Schlagebene',
  'FieldInfo': 'Feldinformationen',
  'FieldLevel': 'Schlagebene',
  'FieldLocationPrompt': 'Drücken Sie fest auf der Karte, um die Position des Feldes festzulegen, oder drücken Sie den Pfeil auf der linken Seite, um Ihren Standort zu verwenden.',
  'FieldManagement': 'Feldmanagement',
  'FieldMissingCurPolicyHarvest': 'Diesem Feld ist kein aktueller Vertrag und keine Ernte zugewiesen; bitte klicken Sie hier, um einen zuzuweisen.',
  'FieldReference': 'Feldreferenz',
  'FieldScoring': 'Feld Score',
  'FieldShapeAndFarm': 'Feldgrenzen und Bauernhof',
  'Fields': 'Felder',
  'FileActions': 'Dateiaktionen',
  'FilterList': 'Filterliste',
  'Filters': 'Filter',
  'FirstAndLastName': 'Erster Nachname',
  'FirstName': 'Vorname',
  'FloodZone': 'Flutzone',
  'ForgotPassword': 'Passwort zurücksetzen',
  'FormSubmitted': 'Gespeichert!',
  'FrontPageReportDisclaimer': 'Vorbehaltlich der Versicherungsbedingungen',
  'FungicideApplied': 'Fungizid angewendet',
  'GPSModuleUnavailable': 'GPS-Modul nicht verfügbar',
  'Generate': 'Generieren',
  'GenerateReport': 'Bericht generieren',
  'GeneratingReportPleaseWait': 'Bericht generieren, bitte warten ...',
  'GeographicalArea': 'Geographisches Gebiet',
  'GetDirectionsField': 'Wegbeschreibung zum Feld erhalten',
  'GrainMoisture': 'Getreidefeuchtigkeit',
  'GrainMoistureReduction': 'Feuchtigkeitsabzug',
  'GrantsTo': 'Zuschüsse zu',
  'GrapeVariety': 'Traubensorte',
  'GrossSampleProduction': 'Bruttogewicht',
  'GuaranteedLosses': 'Garantierte Verluste',
  'HUN': 'Ungarn',
  'HailInfo': 'Diese Ebene zeigt das Risiko von Hagelereignissen an.',
  'Harvest': 'Ernte',
  'HarvestArea': 'Anbaufläche',
  'HarvestAreaMustBePositiveAndLessThanFieldArea': 'Die Erntefläche muss positiv sein und darf nicht größer als die Feldfläche sein!',
  'HarvestFormComment': 'Zusätzliche Ernteinformationen',
  'HarvestInfo': 'Ernte Informationen',
  'HarvestLoss': 'Ernteverlust',
  'HarvestModificationDisabledMsg': 'Dieser Ertrag kann nicht geändert werden, da er vom Versicherer erstellt wurde.',
  'HarvestPlotId': 'Teilstück',
  'HarvestPractice': 'Ernte-Praxis',
  'HarvestReference': 'Erntereferenz',
  'HarvestYear': 'Erntejahr',
  'Harvester': 'Mähdrescher',
  'HarvesterDistanceHarvested': 'Länge Probenstrecke',
  'HarvesterPlatformWidth': 'Arbeitsbreite',
  'Harvests': 'Ernten',
  'HarvestsWillNotBeUpdated': 'Die Ernten dieser Felder werden nicht aktualisiert.',
  'HelloReportIssue': 'Hallo, ich möchte über folgendes Problem mit der GreenTriangle App berichten:',
  'HerbicideApplied': 'Herbizid angewendet',
  'HereAddAsNew': 'Klicken Sie hier, um stattdessen einen neuen Betrieb hinzuzufügen.',
  'HideDataFromMap': 'Daten von Karte ausblenden',
  'HighHailRisk': 'Hohes Hagelschadenrisiko',
  'HighPWind': 'Starker Sturm (28.5-32.5 m/s)',
  'HighPrecipitation': 'Hoher Niederschlag (30-50 mm/Wo.)',
  'HighSoilMoisture': 'Etwas feuchter als üblich',
  'HighTemperature': 'Leichte Hitzewelle (25°C<Tmax<29°C)',
  'HighVegetation': 'Etwas höhere Gesundheit der Vegetation als üblich',
  'Higher': 'höher',
  'HigherSoilMoisture': 'Feuchter als üblich',
  'HigherTemperature': 'Mäßige Hitzewelle (29°C<Tmax<34°C)',
  'HigherVegetation': 'Höhere Gesundheit der Vegetation als üblich',
  'Highest': 'höchste',
  'HighestSoilMoisture': 'Viel feuchter als sonst',
  'HighestTemperature': 'Schwere Hitzewelle (34°C<Tmax<38°C)',
  'HighestVegetation': 'Viel höhere Gesundheit der Vegetation als üblich',
  'HistoricalDeclaredYields': 'Historische deklarierte Erträge',
  'HistoricalObservedYields': 'Historisch beobachtete Erträge',
  'HistoricalRealizedYields': 'Ertagshistorie',
  'Homogeneity': 'Homogenität',
  'HomogeneityInfo': 'Homogenität gibt die Gleichmäßigkeit des Wachstums auf dem Feld wieder.',
  'ID': 'AUSWEIS',
  'ITA': 'Italien',
  'ImportData': 'Daten importieren',
  'Impurities': 'Verunreinigungen',
  'Info': 'Die Info',
  'InheritedFrom': 'Geerbt von',
  'Initials': 'Initialen',
  'InsecticideApplied': 'Angewandtes Insektizid',
  'Insee': 'INSEE',
  'Install': 'Installieren',
  'InsuredArea': 'Versicherte Fläche',
  'InsuredCropRequired': 'Wählen Sie mindestens eine versicherte Ernte aus, um fortzufahren.',
  'InsuredCrops': 'Versicherte Ernten',
  'InsuredPrice': 'Versicherter Preis',
  'InsuredProduction': 'Versicherte Produktion',
  'InsuredYield': 'Versicherter Ertrag',
  'InterfieldInfo': '"Feld Vergleich" zeigt den absoluten Zustand der Vegetation an und erlaubt den direkten Vergleich des allgemeinen Vegetationszustandes zwischen verschiedenen Parzellen.',
  'InteryieldInfo': 'Die Ertragsvorhersage der Rendite zeigt den erwarteten Ertrag auf Basis der aktuellen Satelliten- und Klimadaten.',
  'IntrafieldInfo': '"Intra-Feld Variabilität" zeigt den normalisierten Vegetationszustand für die überwachte Fläche an. Die Beobachtungsfläche ist in 5 Zonen mit gleicher Fläche unterteilt, die 20% (oder Quintil) der Indexwerte entsprechen, von der niedrigsten bis zur höchsten.',
  'InvalidShapeTraced': 'Ihre Form darf keine sich kreuzenden Linien haben. Bitte zeichnen Sie eine einfache Form ohne Kreuzungen.',
  'Irrigated': 'Bewässert',
  'IrrigatedQuestionMark': 'Bewässert?',
  'KernelsPerEar': 'Körner pro Ähre',
  'KernerlsPerUnit': 'Körner pro ertagsbildende Pflanzenteile',
  'LTU': 'Litauen',
  'LastChanged': 'Zuletzt geändert',
  'LastName': 'Familienname, Nachname',
  'Legend': 'Legende',
  'LessDetails': 'Weniger Details',
  'LinkCopiedToClipboard': 'Der Link wurde in Ihre Zwischenablage kopiert',
  'LinkToThisLocation': 'Link zu diesem Standort',
  'List': 'Liste',
  'LiveSampleFeed': 'Live Sample Feed',
  'Loading': 'Ladet',
  'Locale': 'Sprache',
  'Location': 'Standort',
  'LocationAndFarm': 'Lage und Bauernhof',
  'LocationMocked': 'Es sieht so aus, als ob Ihr Standort verspottet wird. Bitte geben Sie Ihren tatsächlichen Standort an, um fortzufahren.',
  'LocationNotProvided': 'Der Standort muss aktiviert sein, um diese Funktion nutzen zu können.',
  'Login': 'Anmelden',
  'Logout': 'Abmelden',
  'LossAdjuster': 'Schadensexpert',
  'LossCause': 'Ursache der Ertragseinbüssen',
  'LossCauseRecognized': 'Schadensursache erkannt',
  'LossEstimation': 'Schätzung des Schadens',
  'LossEventDate': 'Datum des Schadenereignisses',
  'Losses': 'Schaden',
  'LowHailRisk': 'Geringes Hagelschadenrisiko',
  'LowPrecipitation': 'Geringer Niederschlag (10-15 mm/Wo.)',
  'LowSoilMoisture': 'Etwas trockener als üblich',
  'LowTemperature': 'Leichte Frostgefahr (-1°C<Tmin<2°C)',
  'LowVegetation': 'Etwas geringerer Gesundheitszustand der Vegetation als üblich',
  'LowWind': 'Mässiger Wind (17-24.5 m/s)',
  'Lower': 'tiefer',
  'LowerSoilMoisture': 'Trockener als üblich',
  'LowerTemperature': 'Mäßiges Frostrisiko (-5°C<Tmin<-1°C)',
  'LowerVegetation': 'Niedrigerer Gesundheitszustand der Vegetation als üblich',
  'Lowest': 'tiefste',
  'LowestSoilMoisture': 'Viel trockener als üblich',
  'LowestTemperature': 'Schweres Frostrisiko (-15°C<Tmin<-5°C)',
  'LowestVegetation': 'Deutlich geringerer Gesundheitszustand der Vegetation als üblich',
  'MAR': 'Marokko',
  'MDA': 'Moldawien',
  'MEX': 'Mexiko',
  'ManageUserAccountAndSettings': 'Verwalten Sie Ihre Konten und Einstellungen',
  'Manager': 'Manager',
  'Map': 'Karte',
  'MaxSubsidiesPerMinute': 'Max. Subventionen pro Minute',
  'MeasureDensity': 'Dichte messen',
  'Median': 'Median',
  'Merge': 'Zusammenführen',
  'Method': 'Methode',
  'MixedTemperature': 'Gemischte Zone mit hoher Temperaturschwankung',
  'ModerateHailRisk': 'Mäßiges Hagelschadenrisiko',
  'ModeratePrecipitation': 'Mäßiger Niederschlag (15-30 mm/Wo.)',
  'ModerateWind': 'Stürmischer Wind (24.5-28.5 m/s)',
  'Months': 'Monate',
  'MoreDetails': 'Mehr Details',
  'MoreSpecificHarvestDetected': 'Eine spezifischere Ernte wurde erkannt und wird die bestehende Ernte ersetzen.',
  'MunicipalMedianVegetation': 'Mittlere Vegetation der Gemeinde',
  'Municipality': 'Gemeinde',
  'MustAddCustomer': 'Es muss mindestens ein Kunde hinzugefügt werden',
  'MustAddEmail': 'Es muss eine gültige E-Mail-Adresse angegeben werden',
  'MustSelectACustomer': 'Um fortzufahren, muss ein Kunde ausgewählt werden',
  'MustSelectCropAndYear': 'Sie müssen eine Ernte und ein Jahr auswählen',
  'NDVIRange': 'NDVI-Bereich',
  'NationalYield': 'Nationaler Ertrag',
  'NeedCurrentHarvest': 'Sie müssen eine Ernte für diese Saison hinzufügen, bevor Sie fortfahren können.',
  'NeedHelpContact': 'Probleme mit Ihrem Login? - Kontaktieren Sie uns',
  'New': 'Neu',
  'NewValue': 'Neuer Wert',
  'NewVersionWarning': 'Eine neue Version der App ist verfügbar.',
  'NewVisit': 'Neuer Besuch',
  'Next': 'Nächste',
  'No': 'Nein',
  'NoClaims': 'Keine Forderungen',
  'NoData': 'Keine Einträge gefunden.',
  'NoDataFoundForYourFilters': 'Zu ihren aktuellen Filtereinstellungen gibt es keine passenden Einträge.',
  'NoFarmEditors': 'Diesem Betrieb sind keine Redakteure zugewiesen',
  'NoGpsSupport': 'Dieses Gerät ist nicht mit GPS ausgestattet',
  'NoHailRisk': 'Kein Hagelrisiko',
  'NoOfflineFarms': 'Keine Betriebe für die Offline-Nutzung gespeichert.',
  'NoOngoingVisit': 'Bitte klicken Sie auf (+) oben, um eine neue Schadensschätzung zu starten.',
  'NoPrecipitation': 'Kein, oder sehr geringer Niederschlag (<=15mm/Woche)',
  'NoRainstorm': 'Kein Regensturm (<15 mm/h)',
  'NoSampleDateSelectionWarning': 'Es wurde kein Probenahmedatum ausgewählt. Bitte wählen Sie mindestens eines aus, um einen leeren Bericht zu vermeiden.',
  'NoSheetDataError': 'Das importierte Excel hatte kein „Data“-Blatt. Bitte verwenden Sie die Vorlage',
  'NoTelepacAvailable': 'Telepac nicht verfügbar',
  'NoVariety': 'Keine Sorte',
  'NoWind': 'Wenig oder kein Wind (<17m/s)',
  'NonCompensableLosses': 'Nicht versicherte Gefahren',
  'NonIrrigated': 'Nicht bewässert',
  'NonOrganic': 'Nicht biologisch',
  'NormalSoilMoisture': 'Normal',
  'NormalTemperature': 'Kein Frost-/Hitze-Risiko (2°C<Tmin; Tmax<25°C)',
  'NormalVegetation': 'Normale Vegetation',
  'NotAffected': 'Nicht betroffen',
  'NotAvailable': 'Nicht erhältlich',
  'NumberMatchingFields': 'Anzahl der übereinstimmenden Felder',
  'NumberMatchingSamples': 'Anzahl der übereinstimmenden Probe',
  'NumberOfFields': 'Anzahl von Feldern',
  'NumberOfGrainsPerRow': 'Anzahl der Körner pro Reihe',
  'NumberOfLossAdjusters': 'Anzahl der Schadensexperten',
  'NumberOfPolicies': 'Anzahl von Verträgen',
  'NumberOfRowsPerHead': 'Anzahl Reihen pro Kopf',
  'NumberOfSamples': 'Anzahl von Proben',
  'Occurence': 'Auftritt',
  'OfFieldArea': 'der Feldfläche',
  'OfficialHarvestArea': 'Offizielle Erntefläche',
  'Offline': 'Offline',
  'OfflineAreaMaxLimitReachedMsg': 'Maximales Offlinebereichslimit erreicht.',
  'OfflineAreaTutorialDownload': 'Tippen Sie auf die Schaltfläche „Herunterladen“, um Kartenkacheln für die Offlineverwendung für die ausgewählten Bereiche zu speichern.',
  'OfflineAreaTutorialPanMap': 'Bewegen Sie die Karte mit einem Finger.',
  'OfflineAreaTutorialPinchZoom': 'Zum Vergrößern oder Verkleinern ziehen Sie die Finger auf die Karte.',
  'OfflineAreaTutorialSelectArea': 'Tippen Sie auf einen Bereich, um ihn auszuwählen oder die Auswahl aufzuheben.',
  'OfflineAreas': 'Offline-Bereiche',
  'OfflineFarms': 'Offline-Betriebe',
  'Ok': 'Ok',
  'Ongoing': 'Laufend',
  'OpenCamera': 'Zur Kamera',
  'OpenFarm': 'Betrieb öffnen',
  'OpenPolicy': 'Vertrag öffnen',
  'OpenVisit': 'Offener Besuch',
  'Or': 'oder',
  'Organic': 'Bio',
  'OrganicQuestionMark': 'Bio?',
  'OtherCompensableLosses': 'Andere versicherte Gefahren',
  'OtherFields': 'Andere Felder',
  'OverrideCalculation': 'Gesamtertrag überschreiben',
  'OverrideEstimatedYield': 'Geschätzten Ertrag überschreiben',
  'Overview': 'Überblick',
  'PER': 'Peru',
  'POL': 'Polen',
  'PRY': 'Paraguay',
  'Password': 'Passwort',
  'PasswordMissing': 'Bitte geben Sie Ihr Passwort ein.',
  'PastMonth': 'Letzter Monat',
  'PastReportsClickToRefresh': 'Alte Berichte - Klicken Sie auf Aktualisieren:',
  'PastWeek': 'Letzte Woche',
  'PastYear': 'Vergangenes Jahr',
  'Pause': 'Pause',
  'Paused': 'Angehalten',
  'PeakVegetationDate': 'Tag der höchsten Biomassezuwachs',
  'PendingDownload': 'Ausstehender Download',
  'PendingUpload': 'Ausstehender Upload',
  'PerCropSignature': 'Signatur pro Ernte',
  'Phone': 'Telefon',
  'Photo': 'Foto',
  'Photos': 'Fotos',
  'PhotosAndReferences': 'Fotos und Referenzen',
  'PixelResolution': 'Pixelauflösung',
  'PlanetTiles': 'Planet-Bilder',
  'PlanetTilesBoundsWarning': 'Für das ausgewählte Datum und Gebiet sind keine Kacheln verfügbar. Bitte wählen Sie ein anderes Datum oder Gebiet.',
  'PlantDensity': 'Pflanzendichte',
  'PlantsCollected': 'Anzahl geschätzter Pflanzen (pro Reihenlänge)',
  'PleaseAddFields': 'Bitte fügen Sie einige Felder hinzu, um fortzufahren',
  'PleaseConnect': 'Keine Internetverbindung entdeckt. Um sich einzuloggen, stellen Sie bitte sicher, dass Sie mit dem Internet verbunden sind!',
  'PleaseEnableLocation': 'Bitte Standort für GreenTriangle aktivieren.',
  'PleaseEnablePhotoPermissions': 'Um fortzufahren, aktivieren Sie die Kamera in Ihren Einstellungen',
  'PleaseEnterEmail': 'Bitte geben Sie eine E-Mail ein.',
  'PleaseEnterValue': 'Bitte geben Sie einen Wert ein.',
  'PleaseReviewReportBeforeSigning': 'Bitte überprüfen Sie den Bericht, bevor Sie ihn unterzeichnen',
  'PleaseSelectClaim': 'Bitte Anspruch auswählen',
  'PleaseSelectDate': 'Bitte wählen Sie ein Datum.',
  'PleaseSelectFarm': 'Bitte wählen Sie einen Betrieb aus, um fortzufahren',
  'PleaseSelectField': 'Bitte wählen Sie ein Feld aus, um fortzufahren',
  'PleaseSelectLocation': 'Bitte wählen Sie einen Standort aus, um fortzufahren.',
  'PleaseSelectPolicy': 'Bitte wählen Sie eine Police aus, um fortzufahren',
  'PleaseUpgradeLatest': 'Ihre GreenTriangle-Version ist veraltet. Bitte aktualisieren Sie auf die neueste Version.',
  'PointOrZone': 'Daten-Punkt/ Daten-Zone',
  'Policies': 'Verträge',
  'Policy': 'Vertrag',
  'PolicyFormComment': 'Zusätzliche Richtlinieninformationen',
  'PolicyNumber': 'Vertragsnummer',
  'PolicyProcessingStartEnd': 'Beginn und Ende der Policenbearbeitung',
  'Polygon': 'Polygon',
  'Portfolio': 'Portfolio',
  'PortfolioReport': 'Portfoliobericht',
  'PotentialDuplicates': 'Die folgenden Einträge sehen ähnlich aus. Erwägen Sie die Auswahl einer der folgenden Optionen',
  'Practice': 'Praxis',
  'PrecipitationInfo': 'Der wöchentliche Gesamtniederschlag bezeichnet angesammeltes flüssiges und gefrorenes Wasser, einschließlich Regen und Schnee, das innerhalb von 7 Tagen auf die Erdoberfläche fällt. Es enthält keinen Nebel, Tau oder Niederschlag, der in der Atmosphäre verdunstet, bevor er an der Erdoberfläche landet.',
  'Preview': 'Vorschau',
  'PreviewAndSend': 'Vorschau & Senden',
  'Previous': 'Vorherige',
  'PreviousDate-s': 'Vorherige(s) Datum/Daten',
  'PriceRetainedByExpert': 'Der Preis bleibt beim Experten',
  'Primary': 'Primär',
  'PrimaryYieldLoss': 'Primärer Ertragsverlust',
  'PrivacyPolicy': 'Datenschutzrichtlinie',
  'Processing': 'Wird verarbeitet',
  'Productivity': 'Produktivität',
  'ProductivityInfo': 'Produktivität gibt die historischen Erträge des Feldes wieder.',
  'QuoteProcessingStartEnd': 'Beginn und Ende der Angebotsbearbeitung',
  'ROU': 'Rumänien',
  'RUS': 'Russland',
  'RainstormInfo': 'Maximaler stündlicher Niederschlag innerhalb dieser Woche.',
  'RainstormLevel1': 'Regensturm Stufe 1 (15-25 mm/h)',
  'RainstormLevel2': 'Regensturm Stufe 2 (25-35 mm/h)',
  'RainstormLevel3': 'Regensturm Stufe 3 (35-50 mm/h)',
  'RainstormLevel4': 'Regensturm Stufe 4 (>50 mm/h)',
  'Recipients': 'Empfänger',
  'RecognizedHazard': 'Anerkannte Schadensursache',
  'Reference': 'Referenz',
  'Refresh': 'Aktualisierung',
  'Region': 'Region',
  'RegionLevel': 'Regionsebene',
  'RegionLevel_commune': 'Kommune',
  'RegionLevel_country': 'Land',
  'RegionLevel_state': 'Zustand',
  'RegionalStatistic': 'Regionalstatistik',
  'Regions': 'Regionen',
  'RelativeScoring': 'Relativer Score',
  'RelativeScoringInfo': 'Der Relative Score berechnet sich aus dem Feld Score und dem Median Score von Feldern in der Region (ca. 100 Felder werden berücksichtig)',
  'Remove': 'Entfernen',
  'RemoveCrop': 'Ernte löschen',
  'RemoveFarm': 'Farm entfernen',
  'RemoveField': 'Feld entfernen',
  'RemoveHarvest': 'Ernte entfernen',
  'RemoveLoss': 'Verlust beseitigen',
  'RemovedGroups': 'Gruppen entfernt',
  'RenewHarvests': 'Ernte erneuern',
  'ReportDate': 'Berichtsdatum',
  'ReportDoesntExist': 'Bericht existiert nicht',
  'ReportGenerationPageComment': 'Allgemeiner Kommentar zum Bericht',
  'ReportNoFields': 'Diese Farm hat keine Felder, über die ein Bericht erstellt werden kann!',
  'Reset': 'Zurücksetzen',
  'ResetLink': 'Einen Reset-Link senden',
  'Restore': 'Wiederherstellen',
  'Resume': 'Übernehmen',
  'Risks': 'Risiken',
  'RowDistanceCollected': 'Beprobte Reihenlänge',
  'RowsAndWidth': 'Reihen & Breite',
  'SRB': 'Serbien',
  'Sample': 'Probe',
  'Sample-s': 'Probe(n)',
  'SampleCropTitle': 'Proben nach Kulturtyp',
  'SampleDate': 'Probendatum',
  'SampleDate-s': 'Beispieldatum(e)',
  'SampleFormComment': 'Kommentar / Beobachtung',
  'SampleFormMissingImagesWarning': 'Sie haben für dieses Beispiel kein Foto aufgenommen. Bitte fügen Sie eins hinzu, um fortzufahren.',
  'SampleLocation': 'Standort der Probe',
  'SampleLocationInaccurate': 'Ungenauer Standort – Bitte aktivieren Sie den genauen Standort, falls noch nicht geschehen. Klicken Sie dann erneut auf „Meinen Standort nutzen“.',
  'SampleLocationOutsideField': 'Der Probenort liegt nicht im ausgewählten Feld.',
  'SampleLocationPrompt': 'Wählen Sie das Feld der Probe auf der Karte aus und drücken Sie dann fest auf die Karte, um den Ort der Probe festzulegen, oder drücken Sie den Pfeil auf der linken Seite, um Ihre aktuelle Position zu verwenden.',
  'SampleType': 'Beispielstyp',
  'SampledArea': 'Abgeschätzte Fläche',
  'SampledBy': 'Abgeschätzt von',
  'Samples': 'Proben',
  'SamplingDate-s': 'Probenahmedatum/-daten',
  'Save': 'Speichern',
  'SaveOffline': 'Offline speichern',
  'Saving': 'Speichern',
  'Score': 'Score',
  'Search': 'Suchen',
  'SearchAddTipLabel': 'Suchbegriffe kombinieren',
  'SearchExampleDescAddedBy': 'Geben Sie eine E-Mail-Adresse ein, um die von diesem Benutzer hinzugefügten Proben sowie die Felder und Bauernhöfe dieser Proben anzuzeigen.',
  'SearchExampleDescAddress': 'Geben Sie eine Adresse ein, um die Karte auf diesen Ort neu zu zentrieren.',
  'SearchExampleDescCropCondition': 'Geben Sie eine Kulturbedingung ein, um Proben hervorzuheben, bei denen die Kulturbedingung auf eine bestimmte Art und Weise bewertet wurde.',
  'SearchExampleDescFarm': 'Finden Sie einen Bauernhof und seine Felder & Proben, nach Name oder Adresse',
  'SearchExampleDescHarvest': 'Suchen Sie alle Elemente, die sich auf eine bestimmte Ernte beziehen, indem Sie einen Kulturtyp, eine Jahreszeit oder ein Jahr eingeben. Wenn Sie eine Ernteart eingeben, werden die Ergebnisse auf dieses Jahr beschränkt. Sie können in einer zweiten Suche nach älteren Erntejahren suchen.',
  'SearchExampleDescPolicy': 'Alle Artikel zu einer Vertragsnummer finden',
  'SearchExampleLabelAddedBy': 'über eine E-Mail-Adresse hinzugefügte Proben finden',
  'SearchExampleLabelAddress': 'Stadt, Adresse oder Land',
  'SearchExampleLabelCropCondition': 'Proben für eine gegebene Kulturbedingung finden (z.B. "schlecht")',
  'SearchExampleLabelFarm': 'Suche nach Betriebsnamen und -adresse',
  'SearchExampleLabelHarvest': 'Suche nach Ernte, z.B. "Weizen"',
  'SearchExampleLabelPolicy': 'Suche nach Vertragsnummer',
  'SearchExampleLabelUserGroup': 'Elemente in bestimmten Portfolio(s) finden',
  'SearchForClaimNumber': 'Suchen Sie nach der Anspruchsnummer',
  'SearchVisit': 'Nicht zugewiesenen Besuch finden',
  'Secondary': 'Sekondär',
  'SecondaryYieldLoss': 'Sekundäre Ertragseinbußen',
  'SeedWeightPerHead': 'Körnergewicht pro Kopf',
  'Select': 'Auswählen',
  'SelectAll': 'Wählen Sie Alle',
  'SelectAppellation': 'Appellation auswählen',
  'SelectCostType': 'Kostenart auswählen',
  'SelectCropCondition': 'Kulturbedingung auswählen',
  'SelectCropType': 'Kultur auswählen',
  'SelectCurHarvest': 'Aktuelle Ernte auswählen',
  'SelectDate': 'Datum auswählen',
  'SelectFarm': 'Betrieb auswählen',
  'SelectFarmsForOfflineAccess': 'Wählen Sie Betriebe für den Offline-Zugriff aus',
  'SelectField': 'Feld auswählen',
  'SelectFieldCurHarvest': 'Wählen Sie die aktuelle Ernte des Feldes',
  'SelectFieldCurPolicy': 'Wählen Sie den aktuellen Vertrag des Feldes',
  'SelectHarvest': 'Ernte wählen',
  'SelectHarvestYear': 'Erntejahr auswählen',
  'SelectLossCause': 'Ursache der Ertragseinbüssen auswählen',
  'SelectOneGroup': 'Bitte wählen Sie nur Einträge aus einem einzigen Portfolio',
  'SelectPac': 'Bitte wählen Sie eine gültige Datei (.ZIP, .XML, .KML, GeoJSON) aus. Wenn Sie sicher sind, dass diese Datei korrekt ist, kontaktieren Sie uns bitte unter support@green-triangle.com.',
  'SelectPolicy': 'Wählen Sie einen Vertrag',
  'SelectPortfolio': 'Portfolio auswählen',
  'SelectReportType': 'Berichtstyp auswählen',
  'SelectSampleType': 'Wählen Sie den Probentyp aus',
  'SelectSubplotId': 'Teilstück auswählen',
  'SelectVariety': 'Sorte auswählen',
  'SelectVegetationStage': 'Vegetationsstadium auswählen (BBCH)',
  'SelectVisitType': 'Wahl: Art der Schadenschätzung',
  'SelectWillOverwriteShape': 'Sind Sie sicher, dass Sie dieses Feld wählen wollen? Das aktuelle Feld wird überschrieben.',
  'SelectYear': 'Jahr auswählen',
  'SelectedAppellation': 'Ausgewählte Appellation',
  'SelectedCrops': 'Ausgewählte Kulturen',
  'SelectedField': 'Ausgewähltes Feld',
  'Send': 'Senden',
  'SendFeedback': 'Feedback senden',
  'SendTo': 'Senden an',
  'Sending': 'Senden',
  'Sent': 'Gesendet',
  'Settings': 'Einstellungen',
  'SevereHailRisk': 'Schweres Hagelschadenrisiko',
  'SeverePrecipitation': 'Starke Niederschläge (75-100 mm/Wo.)',
  'SevereWind': 'Hurrikan (>32.5 m/s)',
  'ShapeTracerAddPoint': 'Drücke auf die Karte, um einen Punkt hinzuzufügen, oder drücke auf einen Punkt, um seine Position zu ändern oder diesen zu löschen.',
  'ShapeTracerEditPoint': 'Bewege den Punkt mit dem Stift oder drücke auf eine andere Stelle, um den Eintrag zu validieren.',
  'Share': 'Teilen',
  'ShowAll': 'Zeige alles',
  'ShowDataOnMap': 'Daten auf Karte anzeigen',
  'ShowLastAdded': 'Zuletzt hinzugefügt anzeigen',
  'ShowOnMap': 'Auf der Karte anzeigen',
  'ShowSamplesAddedIn': 'Zuletzt hinzugefügte Beispiele anzeigen:',
  'SignAndClose': 'Unterschreiben und schließen',
  'SignatoryName': 'Name des Unterzeichners',
  'SignatoryNames': 'Namen der Unterzeichner',
  'Signature': 'Unterschrift',
  'Signatures': 'Unterschriften',
  'SignedByFarmer': 'Unterzeichnet vom Landwirt',
  'SnowIce': 'Schnee/Eis',
  'SoilMoistureInfo': 'Der "Bodenfeuchtigkeit Anomalie Index" quantifiziert den Feuchtigkeitszustand in 40 cm Tiefe im Boden für einen Zeitraum von 10 Tagen im Verhältnis zum langfristigen Durchschnitt (10 Jahre) über denselben Zeitraum.',
  'SomethingWentWrongError': 'Etwas ist schief gelaufen.',
  'SowingDate': 'Aussaat-Datum',
  'SowingDensity': 'Aussaatdichte',
  'SpaceBetweenPlants': 'Pflanzabstand',
  'SpaceBetweenRows': 'Reihenabstand',
  'SquareArea': 'Platzbereich',
  'SquareMeterCut': 'Quadratmeterschnitt  (Pflanzendichte)',
  'SquareSide': 'Die Seite des Platzes',
  'SquareSize': 'Quadratische Größe',
  'Stability': 'Stabilität',
  'StabilityInfo': 'Stabilität gibt die Gleichmäßigkeit der Produktivität des Feldes wieder.',
  'StartDate': 'Startdatum',
  'StartDateTime': 'Startdatum und -uhrzeit',
  'StartingYield': 'Machbarer Ertrag',
  'Status': 'Status',
  'SubjectToValidationOfReseedingByExpert': 'Vorbehaltlich der Bestätigung der Nachsaat durch den Experten',
  'Subplots': 'Nebenhandlungen',
  'SubsidySettings': 'Subventionseinstellungen',
  'Summary': 'Zusammenfassung',
  'SupportedSurface': 'Unterstützte Oberfläche',
  'SureAddSampleWithoutField': 'Sind sie sicher, dass sie eine Probe ohne Angabe eines Feldes anlegen möchten?',
  'SureCancel': 'Sind Sie sicher, dass Sie vor dem Speichern Ihrer Daten abbrechen möchten?',
  'SureCloseVisit': 'Sind sie sicher, dass sie die Schätzung beenden wollen? Der Bericht ist noch nicht unterschrieben.',
  'SureCloseVisitPartial': 'Sind sie sicher, dass sie die Schätzung beenden wollen? Der Bericht ist noch nicht vollständig unterschrieben.',
  'SureDeleteFarm': 'Sind Sie sicher, dass Sie diesen Betrieb löschen wollen?',
  'SureDeleteField': 'Sind Sie sicher, dass Sie dieses Feld löschen möchten?',
  'SureDeleteHarvest': 'Sind Sie sicher, dass Sie dieses Erntejahr löschen möchten?',
  'SureDeleteSample': 'Sind Sie sicher, dass Sie diese Probe löschen möchten?',
  'SureDeleteVisit': 'Sind Sie sicher, dass Sie diesen Besuch löschen möchten?',
  'SureReset': 'Möchten Sie wirklich zurücksetzen? Alle Ihre Offline-Änderungen gehen dabei verloren.',
  'SureSelectObject': 'Sind Sie sicher, dass Sie stattdessen Folgendes auswählen möchten:',
  'SureSignOut': 'Sind Sie sicher, dass Sie sich abmelden möchten? Sie werden alle Offline-Bearbeitungen verlieren.',
  'SurfacePAC': 'Oberflächen-PAC',
  'SurfaceTemperatureInfo': 'Diese Ansicht zeigt die wöchentliche minimale und maximale Bodentemperatur. Dies kann auf Frost- oder Hitzerisiko hinweisen',
  'Syncing': 'Synchronisierung',
  'TableNoRowsFound': 'Keine Reihen gefunden',
  'TableOf': 'von',
  'TablePage': 'Seite',
  'TableRows': 'Reihen',
  'TelepacAvailableFor': 'Telepac hochgeladen für',
  'TelepacNr': 'Telepac-Nummer',
  'TelepacReport': 'PAC-Import',
  'TemperatureInfo': 'Diese Schicht zeigt die wöchentlichen Minimal- und Maximaltemperaturen an, um das Risiko von Frost- und Hitzewellen zu erkennen.',
  'Tertiary': 'Tertiär',
  'TertiaryYieldLoss': 'Tertiäre Ertragseinbußen',
  'ThousandKernelWeightGrams': '1000-Korn-Gewicht (g)',
  'TopNCropsByHarvestArea': 'Spitzenfrüchte nach Erntegebiet',
  'Total': 'Gesamt',
  'TotalArea': 'Gesamtfläche',
  'TotalCultivatedArea': 'Anbaufläche (gesamt)',
  'TotalEarsDetected': 'Gesamtzahl der Ähren erkannt',
  'TotalHarvestArea': 'Gesamterntefläche',
  'TotalLoss': 'Gesamtverlust',
  'TotalYieldPctLoss': 'Gesamtertragsverlust (%)',
  'UKR': 'Ukraine',
  'URY': 'Uruguay',
  'USA': 'USA',
  'UnableToUpload': 'Hochladen nicht möglich',
  'UnassignMyself': 'Meine Zuweisung aufheben',
  'Uncategorized': 'Unkategorisiert',
  'Undo': 'Rückgängig machen',
  'UnguaranteedLosses': 'Ungarantierte Verluste',
  'Unit': 'Einheit',
  'UnitsPerPlant': 'Anzahl ertagsbildende Pflanzenteile (Ähre, Kolben, Traube, etc)',
  'UnknownErrorOccurred': 'Ein unbekannter Fehler ist aufgetreten',
  'UpcomingInspection': 'Bevorstehende Inspektion',
  'Update': 'Aktualisieren',
  'UpdateMemberships': 'Mitgliedschaften aktualisieren',
  'UploadPACFile': 'PAC-Datei hochladen',
  'Usage': 'Verwendung',
  'UseMyLocation': 'Meinen Standort nutzen',
  'User': 'Benutzer',
  'UserAdminNoAccess': 'Es gibt keine Überschneidungen zwischen den ausgewählten Benutzerkunden und Ihren Benutzer-Administratorrechten.',
  'UserAdminNoCommon': 'Die ausgewählten Benutzer haben kein gemeinsames Unternehmen.',
  'UserAdministration': 'Benutzerverwaltung',
  'UserGroup': 'Benutzergruppe',
  'Validation': 'Validierung',
  'VegetationInfo': 'Die "Anomalie der Vegetationsgesundheit" bietet ein alternatives Maß für die relative Gesundheit der Vegetation im Vergleich zu den Vorjahren (seit 2001). Dieser Indikator kann verwendet werden, um die Gebiete zu überwachen, in denen die Vegetation möglicherweise gestresst ist, als Proxy zur Erkennung einer möglichen Dürre.',
  'VegetationStage': 'Vegetationsphase',
  'VeryHighPrecipitation': 'Sehr hoher Niederschlag (50-75 mm/Wo.)',
  'VeryLowPrecipitation': 'Sehr wenig Niederschlag (5-10 mm/Wo.)',
  'ViewThisInstead': 'Möchten Sie dieses Formular verlassen und stattdessen Folgendes öffnen:',
  'VinificationRatio': 'Ausbeute (kg/hl)',
  'Visit': 'Besuchen',
  'VisitAssign': 'Besuch zuweisen',
  'VisitCandidateStatus_accepted': 'Akzeptiert',
  'VisitCandidateStatus_assigned': 'Zugewiesen',
  'VisitCandidateStatus_rejected': 'Abgelehnt',
  'VisitList': 'Liste Schadensschätzung',
  'VisitMode': 'Schadensschätzung',
  'VisitReport': 'Bericht Schadensschätzung',
  'VisitReportEmailInfoMsg': 'Dadurch wird der unterschriebene Besuchsbericht an die ausgewählte E-Mail-Adresse versendet.',
  'VisitType': 'Art der Schadenschätzung',
  'VisitValidation': 'Besuchen Sie die Validierung',
  'VisitYear': 'Besuchsjahr',
  'Visited': 'hat besucht',
  'Visits': 'Besuche',
  'Volatility': 'Volatilität',
  'WebApp': 'Web App',
  'WeightPerPlant': 'Durchschnittliches Pflanzengewicht',
  'WeightPerUnit': 'Gewicht ',
  'WeightedAverage': 'Gewichteter Durchschnitt',
  'WindInfo': 'Die maximale wöchentliche Windböenschicht repräsentiert die maximale Windböe, die innerhalb der letzten 7 Tage in einer Höhe von zehn Metern über der Erdoberfläche aufgezeichnet wurde.',
  'Withdrawal': 'Widerruf',
  'Withdrawn': 'Widerrufen',
  'WouldLikeToRestore': 'Wir haben Daten für dieses Formular gefunden, die aufgrund eines Absturzes oder Update nicht gespeichert wurden. Möchten Sie diese Daten wiederherstellen?',
  'Year': 'Jahr',
  'YearEg': 'Jahr (z.B. 2019)',
  'Yes': 'Ja',
  'YieldAndLoss': 'Ertrag und Verlust',
  'YieldCalcUnsupportedCrop': 'Der Rechner wird für diesen Fruchtart nicht unterstützt. Derzeit ist es nur möglich, die Pflanzendichte zuzuweisen.',
  'YieldEstimationMethod': 'Ertragschätzmethode',
  'YieldLoss': 'Ertragsverlust',
  'YieldLossInputHidden': 'Die Erfassung von Ertragsverlusten ist deaktiviert, da die Verlustursache nicht bekannt ist.',
  'YieldRatio': 'Korrekturfaktor (Wachstumsbedingungen)',
  'YieldTooLarge': 'Ertrag zu hoch',
  'YouMayTryAgain': 'Versuchen Sie es noch einmal',
  'ZAF': 'Südafrika',
  'ZoomInViewLayer': 'Um diese Ebene zu sehen, zoomen Sie hinein.',
  'ZoomOutViewLayer': 'Um diese Ebene zu sehen, zoomen Sie heraus.',
  'additional-costs': 'Zusatzkosten (€)',
  'agroforestry': 'Agroforstwirtschaft',
  'alfalfa': 'Luzerne',
  'allSynced': 'Alles wurde mit der Datenbank synchronisiert',
  'almond': 'Mandeln',
  'ananas': 'Ananas',
  'angelica': 'Engelwurz',
  'anise': 'Anis',
  'apples': 'Äpfeln',
  'apples-cider': 'Äpfel (Apfelwein)',
  'apples-pre-prod': 'Äpfel (5 Jahre oder weniger)',
  'apricots': 'Aprikosen',
  'apricots-pre-prod': 'Aprikosen (5 Jahre oder weniger)',
  'aromatic-plants': 'Aromapflanzen',
  'artichoke': 'Artischocken',
  'asparagus': 'Spargel',
  'avocado': 'Avocado',
  'bananas': 'Bananen',
  'barley': 'Gerste',
  'barley-6-row': 'Mehrzeilige Gerste',
  'barley-malting': 'Malzgerste',
  'barley-seeds': 'Gerste (Saatgut)',
  'base': 'Base',
  'basic': 'Basic',
  'basil': 'Basilikum',
  'bbch-00-grapes': 'A. Winterruhe',
  'bbch-05-grapes': 'B. Wollstadium',
  'bbch-09-grapes': 'C. Grünpunktstadium',
  'bbch-11-grapes': 'D. Austrieb der Blätter',
  'bbch-13-grapes': 'E. Blätter entfaltet',
  'bbch-53-grapes': 'F. Gescheine sichtbar',
  'bbch-55-grapes': 'G. Gescheine vergrößert',
  'bbch-57-grapes': 'H. Einzelblüten trennen sich',
  'bbch-64-grapes': 'I. Vollblüte',
  'bbch-71-grapes': 'J. Beginn der Fruchtentwicklung',
  'bbch-75-grapes': 'K. Beeren sind erbsengroß',
  'bbch-77-grapes': 'L. Beginn Traubenschluss',
  'bbch-81-grapes': 'M. Reifebeginn',
  'bbch-89-grapes': 'N. Ernte',
  'bbch-93-grapes': 'O. Triebentwicklung abgeschlossen',
  'beans': 'Bohnen',
  'beans-dry': 'Trockene Bohnen',
  'beans-green': 'Grüne Bohnen',
  'beans-red': 'Rote Bohnen',
  'beans-seeds': 'Bohnen (Saatgut)',
  'beans-white': 'Weiße Bohnen',
  'beetroot': 'Rote Beete',
  'benchmark-yield': 'Benchmark-Rendite',
  'black-medick': 'Schwarzer Mediziner',
  'blackberry': 'Brombeere',
  'blackcurrant': 'Schwarze Johannisbeere',
  'blueberry': 'Blaubeere',
  'broccoli': 'Brokkoli',
  'bromes': 'Brome',
  'buckwheat': 'Buchweizen',
  'burdock': 'Klette',
  'cabbages': 'Kohl',
  'cabbages-feed': 'Kohl (Futter)',
  'cabbages-red': 'Rotkohl',
  'camelina': 'Kamille',
  'caraway': 'Kümmel',
  'cardoon': 'Kardone',
  'carob': 'Johannisbrot',
  'carrots': 'Har',
  'carrots-feed': 'Karotten (Futter)',
  'carrots-young': 'Möhren',
  'cassava': 'Maniok',
  'castor-bean': 'Rizinus',
  'castration-costs': 'Kastrationskosten (€)',
  'cauliflowers': 'Blumenkohl',
  'cedrate': 'Cedrate',
  'celeri-leaves': 'Sellerie (Blätter)',
  'celeri-root': 'Sellerieknolle',
  'celery': 'Sellerie',
  'cereals': 'Getreide',
  'chamomile': 'Kamille',
  'chard': 'Mangold',
  'cherries': 'Kirschen',
  'chervil': 'Kerbel',
  'chestnut': 'Walnuss',
  'chia': 'Chia',
  'chickpeas': 'Kichererbsen',
  'chicory': 'Chicoree',
  'chilli': 'Chili',
  'chives': 'Schnittlauch',
  'citrus': 'Zitrusfrüchte',
  'claim-all': 'Endgültiger Abschluss der Mission',
  'claim-delivery': 'Endgültiger Abschluss der Ernteergebnisse',
  'claim-some': 'Endgültige Schließung der Kultur',
  'claims-visit': 'Endschätzung',
  'claims-visit-file': 'schätzen',
  'clementines': 'Klementine',
  'clover': 'Klee',
  'cocoa': 'Kakao',
  'coffea': 'Kaffee',
  'cold': 'Frost',
  'compensation-costs': 'Kosten Kompensation (€)',
  'connected': 'Die App ist mit dem Internet verbunden.',
  'coriander': 'Koriander',
  'corn': 'Mais',
  'corn-grain': 'Mais (Körner)',
  'corn-popcorn': 'Mais (Popcorn)',
  'corn-seeds': 'Mais (Saatgut)',
  'corn-seeds-fertile': 'Mais (Saatguterzeugung, fertil)',
  'corn-seeds-sterile': 'Mais (Saatguterzeugung, steril)',
  'corn-silage': 'Mais (Silo)',
  'corn-sweet': 'Zuckermais',
  'corn-sweet-seeds-fertile': 'Zuckermais (Saatguterzeugung, fertil)',
  'corn-sweet-seeds-sterile': 'Zuckermais (Saatguterzeugung, steril)',
  'corn-waxy': 'Mais (wachsartig)',
  'cornsalad': 'Feldsalat',
  'cotton': 'Baumwolle',
  'cover-crop': 'Zwischenfrucht',
  'cranberry': 'Preiselbeere',
  'cress': 'Kresse',
  'crop-mon': 'Pflanzenüberwachung',
  'crop_condition': 'Zustand der Kulturpflanzen',
  'crosne': 'Crosne',
  'cucumbers': 'Gurken',
  'cumin': 'Kreuzkümmel',
  'cynara': 'Cynara',
  'dactylis': 'Dactylis',
  'damson': 'Damson',
  'decree': 'Offizieller Erlass',
  'development': '4. Entwicklung erntbarer Pflanzenteile',
  'dill': 'Dill',
  'disease': 'Krankheit',
  'downpour': 'Starkregen',
  'drought': 'Dürre/trockene Bedingungen',
  'eggplant': 'Aubergine',
  'elder': 'Ältere',
  'endives': 'Endiviensalat',
  'excess-water': 'Staunässe',
  'excessive-air-humidity': 'hohe Luftfeuchtigkeit',
  'expected-loss': 'Erwarteter Verlust',
  'fallow-land-perennial': 'Stilllegung (mehrjährig)',
  'fallow-land-spring': 'Stilllegung (einjährig)',
  'false': 'Nein',
  'fennel': 'Fenchel',
  'fenugreek': 'Bockshornklee',
  'festuca': 'Festuca',
  'field-beans': 'Feldbohnen',
  'field-beans-seeds': 'Feldbohnen (Saatgut)',
  'field-crops': 'Feldkulturen',
  'field-mustard': 'Acker-Senf',
  'figs': 'Feigen',
  'fire': 'Feuer',
  'first-crop': 'Erste Ernte',
  'flageolet-bean': 'Bohnen',
  'flood': 'Flut',
  'flood-risk': 'Hochwasserrisiko',
  'flowering': 'Blütezeit',
  'fodder-legumes': 'Leguminosen (Futter)',
  'forest': 'Wald',
  'foxtail-millet': 'Hirse',
  'frost': 'Frost oder Wintersterben',
  'fruit-development': '7. Entwicklung von Früchten',
  'full': 'Voller Zugriff',
  'garlic': 'Knoblauch',
  'gentiana': 'Gentiana',
  'germination': 'Keimung',
  'gherkins': 'Essiggurken',
  'ginger': 'Ingwer',
  'go': 'Go!',
  'good': 'Gut',
  'gooseberries': 'Stachelbeere',
  'gourd': 'Kürbis',
  'grapefruit': 'Grapefruit',
  'grapes': 'Trauben',
  'grapes-food': 'Trauben (Tafel)',
  'grapes-juice': 'Traubensaft',
  'grapes-must': 'Traubenmost',
  'grapes-nursery': 'Rebschule',
  'grapes-table': 'Tafeltrauben',
  'grassland': 'Grassland',
  'grassland-perennial': 'Grünland (mehrjährig)',
  'green-peas': 'Grüne Erbsen',
  'group-allocator': 'Gruppenzuteiler',
  'hail': 'Hagel',
  'harvest': 'Ernte',
  'harvest_crop': 'Kulturpflanzen-Typ',
  'harvested': 'geerntet',
  'harvesting-costs': 'Erntekosten (€)',
  'hazelnuts': 'Haselnüsse',
  'heat-wave': 'Hitzewelle',
  'heatstroke': 'Hitze',
  'heavy-rainfall': 'Starker Regen',
  'hemp': 'Hanf',
  'hemp-paper': 'Hanf (Papier)',
  'hemp-seeds': 'Hanf (Saatgut)',
  'high-res-mapbox': 'Hochauflösend',
  'historical-yield': 'Historisches Ertragsverhältnis',
  'hop': 'Hopfen',
  'hyssop': 'Ysop',
  'inflorescence': '5. Blütenstand-Entstehung / Überschrift',
  'interfield': 'Feld Vergleich',
  'interfield-a': 'Keine Vegetation',
  'interfield-b': 'Dünner Bewuchs',
  'interfield-c': 'Niedrige Vegetation',
  'interfield-d': 'Mäßige Vegetation',
  'interfield-e': 'Mäßige bis dichte Vegetation',
  'interfield-f': 'Dichte Vegetation',
  'interfield-g': 'Hohe Vegetationsaktivität',
  'interfield-h': 'Sehr hohe Vegetationsaktivität',
  'interfield-i': 'Höchste Vegetationsaktivität',
  'interyield': 'Ertragsvorhersage',
  'interyield-a': 'Extrem niedriger Ertrag',
  'interyield-b': 'Sehr niedriger Ertrag',
  'interyield-c': 'Niedriger Ertrag',
  'interyield-d': 'Niedriger bis mittlerer Ertrag',
  'interyield-e': 'Mittlerer Ertrag',
  'interyield-f': 'Mittlerer bis hoher Ertrag',
  'interyield-g': 'Hoher Ertrag',
  'interyield-h': 'Sehr hoher Ertrag',
  'interyield-i': 'Außerordentlich hoher Ertrag',
  'intrafield': 'Intra-Feld Variabiltät',
  'itemsToUpload': 'Es gibt Elemente, die in die Datenbank hochgeladen werden müssen.',
  'jerusalem-artichoke': 'Jerusalem Artischocke',
  'kale': 'Grünkohl',
  'kiwi': 'Kiwi',
  'lack-of-sunlight': 'Zu wenig Sonnenlicht',
  'lavandin': 'Lavandin',
  'lavender': 'Echter Lavendel',
  'leaf-development': '1. Blattentwicklung',
  'leek': 'Lauch',
  'lemon-balm': 'Zitronenmelisse',
  'lemons': 'Zitronen',
  'lentils': 'Linsen',
  'lentils-berry': 'Grünlinsen',
  'lentils-puy': 'Puy Green Linsen',
  'lettuce': 'Kopfsalat',
  'linden': 'Linde',
  'lineseed-textile-seeds': 'Flachs (Saatgut)',
  'linseed': 'Leinsamen',
  'linseed-oilseed': 'Leinsaamen Ölsamen',
  'linseed-textile': 'Flachs',
  'linseed-textile-fiber': 'Linese (Textil/Faser)',
  'linseed-textile-seeds': 'Leinsamen (Samen)',
  'linseed-textile-straw': 'Leinsamen (Textil/Stroh)',
  'lotus': 'Lotus',
  'lupin': 'Lupine',
  'lupin-sweet': 'Lupinen',
  'mandarins': 'Mandarinen',
  'mangos': 'Mangos',
  'meadow-grass': 'Wiesengras',
  'medicinal-plants': 'Medizinische Pflanzen',
  'melilotus': 'Melilotus',
  'melons': 'Melonen',
  'membership_type': 'Art der Mitgliedschaft',
  'meslin': 'Mengkorn',
  'millet': 'Hirse',
  'mint': 'Minze',
  'mirabelles': 'Mirabellen',
  'miscanthus': 'Silbergras',
  'mixed-cereal': 'Getreidemischung',
  'monitoring-visit': 'Inspektionsbesuch',
  'monitoring-visit-file': 'Überwachung',
  'monitoring-with-review': 'Bereitstellung/Verfolgung (mit Verwaltung, ohne Bereitstellung)',
  'monitoring-with-review-provisioning': 'Bereitstellung/Verfolgung (mit Verwaltung, mit Bereitstellung)',
  'monitoring-without-review': 'Provisioning / Monitoring (ohne Management, ohne Provisioning)',
  'monitoring-without-review-provisioning': 'Provisioning/Monitoring (ohne Management, mit Provisioning)',
  'month_0': 'Jan',
  'month_1': 'Feb',
  'month_10': 'Nov',
  'month_11': 'Dec',
  'month_2': 'Mar',
  'month_3': 'Apr',
  'month_4': 'Mai',
  'month_5': 'Jun',
  'month_6': 'Jul',
  'month_7': 'Aug',
  'month_8': 'Sep',
  'month_9': 'Oct',
  'most-recent': 'Neueste',
  'mustard': 'Senf',
  'nashi-pear': 'Nashi Birne',
  'nectarines': 'Nektarinen',
  'noOfflineAreas': 'Um GreenTriangle offline zu verwenden, ist es am besten, Karten auf Ihr Gerät herunterzuladen. Drücken Sie Ja, um dies jetzt zu tun.',
  'none': 'Kein',
  'normal': 'Normal',
  'notConnected': 'Die App ist nicht mit dem Internet verbunden.',
  'not_available': 'Nicht verfügbar',
  'not_planted': 'Nicht gesät',
  'nursery': 'Baumschule',
  'oats': 'Hafer',
  'oats-seeds': 'Hafer (Saatgut)',
  'oilseeds': 'Ölssaten',
  'olives': 'Oliven',
  'onions': 'Zwiebeln',
  'oranges': 'Orangen',
  'orchard': 'Obstgarten (mehr als 5 Jahre)',
  'orchard-pre-prod': 'Obstgarten (5 Jahre oder weniger)',
  'orchards': 'Obstplantagen',
  'oregano': 'Oregano',
  'other-aromatic-medicinal': 'Andere aromatische oder medizinische Pflanzen',
  'other-berries': 'Andere Beeren',
  'other-cereals': 'Andere Cerealien',
  'other-feed': 'Futtergetreide',
  'other-fruits': 'Andere Früchte',
  'other-industry': 'Andere Nutzpflanzen für technische Zwecke',
  'other-legumes': 'Andere Hülsenfrüchte',
  'other-loss': 'Sonstige Schäden',
  'other-oilseed': 'Andere Ölsaamen',
  'other-textile': 'Andere Faserpflanzen',
  'other-vegetables': 'Andere Gemüse',
  'parnsip': 'Pastinaken',
  'parsley': 'Petersilie',
  'peach-blood': 'Blutpfirsich',
  'peach-flat': 'Flacher Pfirsich',
  'peaches': 'Pfirsiche',
  'peaches-pre-prod': 'Pfirsiche (5 Jahre oder weniger)',
  'peanuts': 'Erdnüsse',
  'pears': 'Birnen',
  'pears-pre-prod': 'Birnen (5 Jahre oder weniger)',
  'peas': 'Erbsen',
  'peas-feed': 'Futtererbsen',
  'peas-seeds': 'Erbsen (Saatgut)',
  'peas-split': 'Spalterbsen',
  'pepper': 'Pfeffer',
  'perennial': 'mehrjährig',
  'perennial-crops': 'Dauerkulturen',
  'persimmon': 'Kaki',
  'pest': 'Schädlinge',
  'pickle': 'Gewürzgurken',
  'pineapples': 'Ananas',
  'pistachio': 'Pistazie',
  'pleaseCheckForm': 'Bitte überprüfen Sie die Formularfelder und versuchen Sie es erneut.',
  'plum': 'Pflaume',
  'polyculture': 'Polykultur',
  'poor': 'Schlecht',
  'poppy': 'Mohn',
  'potatoes': 'Kartoffeln',
  'potatoes-industry': 'Kartoffeln (Industrie)',
  'potatoes-seeds': 'Kartoffeln (Samen)',
  'potatoes-starch': 'Kartoffeln (Stärke)',
  'potatoes-ware': 'Speisekartoffeln',
  'precipitation': 'Gesamtniederschlag',
  'predicted-yield': 'Voraussichtliches Ertragsverhältnis',
  'preinspection-none': 'Risikobewertung',
  'preinspection-visit': 'Erstschätzung',
  'preinspection-visit-file': 'Voruntersuchung',
  'production-costs': 'Produktionskosten (€)',
  'pulses': 'Hülsenfrüchte',
  'pumpkin': 'Kürbis',
  'purification-costs': 'Reinigungskosten (€)',
  'quinces': 'Quitte',
  'quinoa': 'Quinoa',
  'radishes': 'Radieschen',
  'rainstorm': 'Regensturm',
  'rapeseed': 'Raps',
  'rapeseed-feed': 'Raps (Futter)',
  'rapeseed-seeds': 'Raps (Saatgut)',
  'raspberry': 'Himbeere',
  'ray-grass': 'Raygras',
  'redcurrant': 'Rote Johannisbeere',
  'remaining-crops': 'Sonstige Kulturen',
  'replanting': 'Neubepflanzung',
  'resowing-costs': 'Kosten Wiederansaat (€)',
  'rhubarb': 'Rhabarber',
  'rice': 'Reis',
  'ripening': 'Reifung',
  'rocket': 'Rakete',
  'root-crops': 'Hackfrüchte',
  'rosemary': 'Rosmarin',
  'rutabaga': 'Steckrübe',
  'rye': 'Roggen',
  'rye-seeds': 'Roggen (Hybrid)',
  'safflower': 'Saflor (falscher Safran)',
  'sage': 'Salbei',
  'sainfoin': 'Esparsetten',
  'salad': 'Salat',
  'salsify': 'Schwarzwurzel',
  'salvia': 'Salbei',
  'sandstorm': 'Sandsturm',
  'satellite': 'Satellite',
  'satureja': 'Satureja',
  'second-crop': 'Zweite Ernte',
  'senescence': '9. Seneszenz, Beginn der Ruhephase',
  'shallots': 'Schalotten',
  'side-shoot': '2. Bildung von Seitentrieben / Bodenbearbeitung',
  'snow': 'Schnee',
  'snow-peas': 'Schneeerbsen',
  'soil-moisture': 'Bodenfeuchtigkeit Anomalie',
  'sorghum': 'Sorghum (Körner)',
  'sorghum-feed': 'Hirse (Futter)',
  'sorghum-seeds': 'Sorghum (Saatgut)',
  'sorghum-silage': 'Sorghum (Silo)',
  'sorrel': 'Sauerampfer',
  'soybeans': 'Soja',
  'soybeans-seeds': 'Sojabohnen (Saatgut)',
  'spelt': 'Dinkel',
  'spinach': 'Spinat',
  'spring': 'Sommer',
  'sprouts': 'Rosenkohl',
  'squash': 'Kürbis',
  'squash-max': 'Kürbis (Cucurbita maxima)',
  'static-data': 'Länderdaten',
  'stevia': 'Süßkraut',
  'storm': 'Sturm',
  'strawberry': 'Erdbeere',
  'sugar-beet': 'Zuckerrübe',
  'sugar-beet-feed': 'Futterrüben',
  'sugar-beet-seeds': 'Zuckerrübe (Saatgut)',
  'sugar-cane': 'Zuckerrohr',
  'sugar-cane-feed': 'Zuckerrohr (Futtermittel)',
  'sunburn': 'Sonnenbrand',
  'sunflower': 'Sonnenblumen',
  'sunflower-oilseed': 'Sonnenblume (Ölsaaten)',
  'sunflower-seeds': 'Sonnenblumen (Saatgut)',
  'surface-temperature': 'Frost & Hitzerisiko (Boden)',
  'switchgrass': 'Rutenhirse',
  'syncDataDb': 'Synchronisieren von Daten mit der Datenbank.',
  'tarragon': 'Estragon',
  'temperature': 'Frost- und Hitzewellerisiko',
  'thistle': 'Distel',
  'thyme': 'Thymian',
  'timothy-grass': 'Lieschgras',
  'tobacco': 'Tabak',
  'tobacco-blond': 'Tabak (Blonde)',
  'tobacco-brown': 'Tabak (Braun)',
  'tobacco-burley': 'Tabak (Burley)',
  'tobacco-virginie': 'Tabak (Virginie)',
  'tomatoes': 'Tomaten',
  'tomatoes-processing': 'Tomaten (Industrie)',
  'tons': 'T',
  'tornado': 'Tornado',
  'triticale': 'Triticale',
  'triticale-seeds': 'Triticale (Samen)',
  'true': 'Ja',
  'turmeric': 'Kurkuma',
  'turnip': 'Speiserübe',
  'turnip-feed': 'Futterrübe',
  'unit': 'Einheit',
  'unknown': 'unbekannt',
  'urtica': 'Brennnesseln',
  'user-admin': 'Benutzer Manager',
  'valerian': 'Baldrian',
  'validation-visit': 'Vorläufige Endschätzung',
  'validation-visit-file': 'Auftreten',
  'validation-with-review': 'Vorkommen mit Management',
  'validation-without-review': 'Vorkommen ohne Management',
  'vanilla': 'Vanille',
  'vegetables': 'Gemüse',
  'vegetation': 'Vegetation Anomalie',
  'vegetative-growth': 'Vegetatives Wachstum',
  'verbena': 'Eisenkraut',
  'very_good': 'Sehr gut',
  'very_poor': 'Sehr schlecht',
  'vetch': 'Wicke',
  'vin-de-table': 'Tafelwein',
  'walnuts': 'Haselnüsse',
  'watermelon': 'Wassermelone',
  'weeds': 'Unkraut',
  'wheat': 'Weizen',
  'wheat-einkorn': 'Einkorn',
  'wheat-feed': 'Futterweizen',
  'wheat-hard': 'Hartweizen',
  'wheat-hard-seeds': 'Durum Weizen (Saatgut)',
  'wheat-seeds': 'Weizen (Saatgut)',
  'wheat-top': 'Weizen (weich – verbessertes Mahlen)',
  'wild-animals': 'Wild',
  'wind': 'Wind',
  'winter': 'Winter',
  'yams': 'Yamswurzel',
  'zucchini': 'Zucchini',
  '-': '-',
  'UnknownLoss': 'unbekannt (Ursache der Ertragseinbüssen)',
  'acres': 'ac',
  'added_by': 'Hinzugefügt von',
  'added_on': 'Hinzugefügt am',
  'arrobas-bra': '@/ha',
  'bags': 'sc',
  'bags-per-hectare': 'sc/ha',
  'bales-per-acre': 'bales/ac',
  'bra_agree_with_result': 'Você concorda com o resultado apurado?',
  'bra_average': 'Média (tipo 2)',
  'bra_clay': 'Argiloso (tipo 3)',
  'bra_insured_facilitated': 'O segurado facilitou o processo de amostragem? (Se não, explique nos comentários)',
  'bra_insured_implemented_zarc_window': 'O segurado implantou a cultura dentro da janela de indicação do ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_last_liming_plastering_date': 'Quando foi feita a última calagem e gessagem na área segurada? (descrever a frequência da análise do solo, qual o perfil da camada do solo é trabalhada, tipo de calcário utilizado, faz uso de AP, já utilizou gesso na lavoura, etc)',
  'bra_losses_from_rnc': 'O perito constatou prejuízos por RNC? Explique os percentuais?',
  'bra_manual': 'Manual',
  'bra_mechanized': 'Mecanizada',
  'bra_necessary_to_subdivide': 'Foi necessário a subdivisão dos itens segurados? (Se sim, explique nos comentários)',
  'bra_planned_location': 'A lavoura foi implantada em local previsto? (Se não, explique nos comentários)',
  'bra_recommended_risk_location': 'A cultivar/variedade semeada é recomendada para o local do risco, conforme ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_sampling_type': 'Qual o tipo de amostragem utilizada para estimativa de produtividade da lavoura?',
  'bra_sandy': 'Arenoso (tipo 1)',
  'bra_soil_type': 'Tipo de solo',
  'bra_treatments_effective': 'Os tratos culturais foram efetivos no manejo da cultura?',
  'bra_uniform_management': 'O manejo empregado na lavoura segurada é igual das áreas de recurso próprio? (Se não, explique nos comentários)',
  'bra_visual': 'Visual',
  'brl': 'R$',
  'brl-per-bag': 'R$/sc',
  'bushels-per-acre': 'bu/ac',
  'centimeters': 'cm',
  'chf': 'Fr.',
  'claim_id': 'Schaden',
  'corn-grain-white': 'Mais (Körner) (white)',
  'corn-grain-yellow': 'Mais (Körner) (yellow)',
  'corn-silage-white': 'Mais (Silo) (white)',
  'corn-silage-yellow': 'Mais (Silo) (yellow)',
  'crop_id': 'Kulturpflanzen-Typ',
  'custom-a': 'Mittlere Vegetation der Gemeinde',
  'decitons-per-hectare': 'qx/ha',
  'development-cereals': '4. Entwicklung erntbarer Pflanzenteile',
  'development-sorghum': '4. Entwicklung erntbarer Pflanzenteile',
  'development-sugar-beet': '4. Entwicklung erntbarer Pflanzenteile',
  'eur': '€',
  'eur-per-hectoliter': '€/hL',
  'eur-per-ton': '€/t',
  'farm_id': 'Betrieb',
  'feet': 'ft',
  'flowering-cereals': 'Blütezeit',
  'flowering-corn': 'Blütezeit',
  'flowering-fruits': 'Blütezeit',
  'flowering-grapes': 'Blütezeit',
  'flowering-legumes': 'Blütezeit',
  'flowering-rapeseed': 'Blütezeit',
  'flowering-sorghum': 'Blütezeit',
  'flowering-soybeans': 'Blütezeit',
  'flowering-sunflower': 'Blütezeit',
  'fruit-development-cereals': '7. Entwicklung von Früchten',
  'fruit-development-corn': '7. Entwicklung von Früchten',
  'fruit-development-fruits': '7. Entwicklung von Früchten',
  'fruit-development-grapes': '7. Entwicklung von Früchten',
  'fruit-development-legumes': '7. Entwicklung von Früchten',
  'fruit-development-rapeseed': '7. Entwicklung von Früchten',
  'fruit-development-sorghum': '7. Entwicklung von Früchten',
  'fruit-development-soybeans': '7. Entwicklung von Früchten',
  'fruit-development-sunflower': '7. Entwicklung von Früchten',
  'germination-cereals': 'Keimung',
  'germination-corn': 'Keimung',
  'germination-fruits': 'Keimung',
  'germination-grapes': 'Keimung',
  'germination-legumes': 'Keimung',
  'germination-rapeseed': 'Keimung',
  'germination-sorghum': 'Keimung',
  'germination-soybeans': 'Keimung',
  'germination-sugar-beet': 'Keimung',
  'germination-sunflower': 'Keimung',
  'grams': 'gr',
  'harvest_year': 'Erntejahr',
  'hectares': 'ha',
  'hectoliters-per-hectare': 'hl/ha',
  'images': 'Fotos',
  'inches': 'in',
  'inflorescence-cereals': '5. Blütenstand-Entstehung / Überschrift',
  'inflorescence-corn': '5. Blütenstand-Entstehung / Überschrift',
  'inflorescence-fruits': '5. Blütenstand-Entstehung / Überschrift',
  'inflorescence-grapes': '5. Blütenstand-Entstehung / Überschrift',
  'inflorescence-legumes': '5. Blütenstand-Entstehung / Überschrift',
  'inflorescence-rapeseed': '5. Blütenstand-Entstehung / Überschrift',
  'inflorescence-sorghum': '5. Blütenstand-Entstehung / Überschrift',
  'inflorescence-sunflower': '5. Blütenstand-Entstehung / Überschrift',
  'kilograms': 'kg',
  'kilograms-per-hectare': 'kg/ha',
  'leaf-development-cereals': '1. Blattentwicklung',
  'leaf-development-corn': '1. Blattentwicklung',
  'leaf-development-fruits': '1. Blattentwicklung',
  'leaf-development-grapes': '1. Blattentwicklung',
  'leaf-development-legumes': '1. Blattentwicklung',
  'leaf-development-rapeseed': '1. Blattentwicklung',
  'leaf-development-sorghum': '1. Blattentwicklung',
  'leaf-development-soybeans': '1. Blattentwicklung',
  'leaf-development-sugar-beet': '1. Blattentwicklung',
  'leaf-development-sunflower': '1. Blattentwicklung',
  'loss_cause': 'Ursache der Ertragseinbüssen',
  'meters': 'm',
  'ounces': 'oz',
  'percent': '%',
  'pln': 'zł',
  'policy_id': 'Vertragsnummer',
  'policy_number': 'Vertragsnummer',
  'ripening-cereals': 'Reifung',
  'ripening-corn': 'Reifung',
  'ripening-fruits': 'Reifung',
  'ripening-grapes': 'Reifung',
  'ripening-legumes': 'Reifung',
  'ripening-rapeseed': 'Reifung',
  'ripening-sorghum': 'Reifung',
  'ripening-soybeans': 'Reifung',
  'ripening-sunflower': 'Reifung',
  'senescence-fruits': '9. Seneszenz, Beginn der Ruhephase',
  'senescence-grapes': '9. Seneszenz, Beginn der Ruhephase',
  'senescence-sorghum': '9. Seneszenz, Beginn der Ruhephase',
  'senescence-soybeans': '9. Seneszenz, Beginn der Ruhephase',
  'senescence-sunflower': '9. Seneszenz, Beginn der Ruhephase',
  'side-shoot-cereals': '2. Bildung von Seitentrieben / Bodenbearbeitung',
  'side-shoot-sorghum': '2. Bildung von Seitentrieben / Bodenbearbeitung',
  'square-centimeters': 'cm²',
  'square-feet': 'ft²',
  'square-inches': 'in²',
  'square-meters': 'm²',
  'thousand-kernel-weight-grams': '1000-Korn-Gewicht (g)',
  'tons-per-acre': 't/ac',
  'tons-per-hectare': 't/ha',
  'units-per-acre': '/ac',
  'units-per-ft2': '/ft²',
  'units-per-hectare': '/ha',
  'units-per-m2': '/m²',
  'usd': '$',
  'user-admin-static-data': 'Länderdaten',
  'user_group': 'Benutzergruppe',
  'vegetative-growth-cereals': 'Vegetatives Wachstum',
  'vegetative-growth-corn': 'Vegetatives Wachstum',
  'vegetative-growth-fruits': 'Vegetatives Wachstum',
  'vegetative-growth-legumes': 'Vegetatives Wachstum',
  'vegetative-growth-rapeseed': 'Vegetatives Wachstum',
  'vegetative-growth-sorghum': 'Vegetatives Wachstum',
  'vegetative-growth-soybeans': 'Vegetatives Wachstum',
  'vegetative-growth-sugar-beet': 'Vegetatives Wachstum',
  'visit_type': 'Art der Schadenschätzung'
} as const;

export default function t(k: I18nSimpleKey|I18nParametric): string {
  if (typeof k == 'string' || typeof k == 'boolean') { return msgs[k]; }
  if (k === null || k == undefined) { console.error('t called with null or undefined!'); return '(∅)'; }
  switch (k.type) {
    case 'AddFarmTimeseriesMaxLimitError': return `Sie haben das Limit von ${k.count} offline zwischengespeicherten Betrieben erreicht. Bitte löschen Sie eine, um fortzufahren.`;
    case 'AdjusterSampledFarm': return `${k.added_by} besuchte den Betrieb ${k.farm_name}`;
    case 'AllocateRemainingHarvestArea': return `Sie können weitere ${intl_num_2(k.remainingArea)}` + t(k.unit) + ` zuweisen.`;
    case 'AreaIsKm2': return `Diese Fläche beträgt ${intl_num(k.area)}km² (ungefähr ${intl_int(k.mb)}MB).`;
    case 'CurFieldPolicyDesc': return `Aktueller Vertrag für das Feld: ${k.policy_number}`;
    case 'CurPortfolio': return `Portfolio: ${k.user_group}`;
    case 'DeleteAlreadyAssignedVisitWarning': return `Sie versuchen, einen Besuch zu löschen, der auch ${k.count} anderen LAs zugeordnet ist.`;
    case 'DeletedUserX': return `Gelöschter Benutzer „${k.user_name}“`;
    case 'DistKmAway': return `${intl_num(k.dist_km)}km entfernt`;
    case 'DownloadExplain': return `Wählen Sie einen Bereich zum Herunterladen für die Offline-Verwendung. Alles in der Karte wird heruntergeladen, in allen Zoom-Stufen. Beachten Sie, dass Sie für die Offline-Verwendung ${intl_int(k.limit_km_2)}km² auf einmal hinzufügen können.`;
    case 'DuplicateFieldId': return `Das Feld ${k.external_field_id} wurde bereits hinzugefügt.`;
    case 'DuplicateSubplotWarning': return `Für diesen Betrieb und Erntejahr gibt es bereits ein Teilstück: ${k.external_harvest_id}. Um eine Probe hinzuzufügen, ändern Sie bitte Ihre Auswahl auf der vorherigen Seite oder wählen Sie eine andere Teilparzellen-ID.`;
    case 'EarsDetectedCount': return `${k.count} Ähren erkannt`;
    case 'ErrorDeletingUserXY': return `Fehler beim Löschen von „${k.user_name}“: ${k.error_message}`;
    case 'EstimatedYieldOverrideInfo': return `Der geschätzte Ertrag wurde zuletzt am ${k.date} überschrieben. Beachten Sie, dass diese Überschreibung den aggregierten geschätzten Ertrag aus den unten stehenden Proben ersetzt.`;
    case 'EstimatedYieldValueUnit': return `Geschätzt: ${intl_num_2(k.val)}` + t(k.unit);
    case 'ExistingHarvestContainsSamples': return `Die vorhandene Ernte enthält ${k.count} Probe(n).`;
    case 'ExoCropUnsupported': return `Diese Funktion unterstützt derzeit nicht ` + t(k.crop) + `.`;
    case 'FeasibleYieldValueUnit': return `Machbar: ${intl_num_2(k.val)}` + t(k.unit);
    case 'FieldFormPickedOneOfMany': return `Warnung: Dieses Beispiel wird nur dem ersten der hinzugefügten ${intl_int(k.numFields)} hinzugefügt.`;
    case 'FileSizeTooBig': return `Diese Datei ist zu groß. Bitte wählen Sie eine Datei mit weniger als ${k.file_size}MB aus.`;
    case 'ImageLocationTooFar': return `Bild #${k.imageIdx} wurde ${intl_num(k.distanceMeters)}` + t(k.unit) + ` vom Probenort entfernt aufgenommen.`;
    case 'InsuredYieldValueUnit': return `Versichert: ${intl_num_2(k.val)}` + t(k.unit);
    case 'InvalidBoolError': return `Dieser Wert konnte nicht als Boolescher Wert analysiert werden: ${k.val}`;
    case 'LossValue': return `Ausfall: ${intl_num(k.yield_loss_pct)}%`;
    case 'LossesMismatchWarning': return `Die Summe der Verluste ist zu groß: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` über der Schätzung.`;
    case 'LossesToAllocate': return `Zuzuordnende Verluste: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` des Gesamtverlusts von ${intl_num_2(k.total)}` + t(k.unit) + `.`;
    case 'NFarmsSelected': return `${intl_int(k.numFarms)} Betriebe ausgewählt`;
    case 'NPoliciesSelected': return `${intl_int(k.numPolicies)} Police(n) ausgewählt`;
    case 'NoAppInstalledToViewFile': return `Keine App zum Anzeigen von ${k.file_name} installiert`;
    case 'NoDeleteClaim': return `Bevor Sie diesen Anspruch löschen können, müssen Sie seine ${intl_int(k.visitCount)} Besuche löschen.`;
    case 'NoDeleteFarm': return `Bevor Sie diese Betrieb löschen können, müssen Sie seine ${intl_int(k.fieldCount)} Felder, ${intl_int(k.sampleCount)} Proben und ${intl_int(k.claimCount)} Ansprüche löschen.`;
    case 'NoDeleteField': return `Bevor Sie dieses Feld löschen, müssen Sie seine ${intl_int(k.sampleCount)} Proben löschen.`;
    case 'NoDeleteHarvest': return `Bevor Sie diese Fruchtart löschen, müssen Sie die ${intl_int(k.sampleCount)} -Stichprobe löschen.`;
    case 'NoDeletePolicy': return `Bevor Sie diesen Vertrag löschen können, müssen Sie seine ${intl_int(k.claimCount)} Ansprüche löschen.`;
    case 'NonUniqueFarm': return `Es wurden zwei Farmen für ${k.key} erkannt, aber mit unterschiedlichen Details: „${k.x1}“ vs. „${k.x2}“.`;
    case 'NumPhotos': return `${intl_int(k.num_photos)} Fotos`;
    case 'OfflineAreaDownloadMore': return `${k.count} Offline-Bereiche übrig.`;
    case 'OfflineAreaMaxLimit': return `Sie haben das Limit von ${k.count} Bereichen erreicht. Bitte deaktivieren Sie einige Bereiche, um fortzufahren.`;
    case 'PasswordResetSent': return `Eine E-Mail zum Zurücksetzen des Passworts wurde an ${k.email} gesendet.`;
    case 'PlantDensityValueUnit': return `Pflanzendichte: ${intl_num(k.val)}` + t(k.unit);
    case 'PolicyDesc': return `Vertrag ${k.policy_number}`;
    case 'ReportContainsTooManyFields': return `Dieser Bericht beinhaltet ${intl_int(k.numFields)} Felder. Die Anzahl ist zu hoch, bitte wählen Sie eine Teilmenge aus.`;
    case 'ReportNoHarvests': return `Diese Farm hat ${intl_int(k.numFields)} Felder, aber keine Ernteinformationen, über die berichtet werden kann!`;
    case 'ResetMsg': return `Eine E-Mail mit einem Reset-Link wurde gesendet an ${k.email}`;
    case 'SampleAbrv': return `${intl_int(k.count)} P.`;
    case 'SampleLocationTooFar': return `Der Beispielstandort ist ${intl_num_2(k.val)}` + t(k.unit) + ` von Ihrem Startstandort entfernt. Wenn Sie Ihren Standort verwenden möchten, klicken Sie oben auf „Meinen Standort nutzen“.`;
    case 'SampleYieldWarning': return `Wenn möglich, füllen Sie bitte entweder ${k.column1} oder ${k.column2} aus.`;
    case 'SearchAddTipDesc': return `Wenn Sie nach mehreren Begriffen suchen möchten (z.B. Weizenernten im Portfolio ${k.firstUserGroup}), können Sie auf das Symbol ⊕ drücken, um einen zusätzlichen Begriff hinzuzufügen.`;
    case 'SearchExampleDescUserGroup': return `Geben Sie einen Portfolionamen ein (z.B. ${k.firstUserGroup}), um alle Elemente in diesem Portfolio anzuzeigen`;
    case 'SearchFoundCount': return `Gefundene ${intl_int(k.farmCount)} Betriebe, ${intl_int(k.fieldCount)} Felder und ${intl_int(k.sampleCount)} Proben.`;
    case 'SearchInfoText': return `Sie können Farmen, Felder, Muster und Verträge finden, indem Sie hier danach suchen. Wenn Sie nach mehreren Begriffen suchen möchten (z.B. Weizenernten im Portfolio ${k.firstUserGroup}), können Sie auf das Symbol ⊕ drücken. Jede Suche wirkt dann wie ein Filter, der die Ergebnisse weiter eingrenzt. In den nachstehenden Beispielen finden Sie einige Möglichkeiten der Suche.`;
    case 'SearchResultAddedBy': return `Proben hinzugefügt von ${k.id}`;
    case 'SearchResultAddress': return `Karte zentrieren auf ${k.address}`;
    case 'SearchResultCropCondition': return `Ernteproben mit Bedingung ${k.id}`;
    case 'SearchResultFarm': return `Betrieb ${k.farmDesc}`;
    case 'SearchResultHarvestCrop': return `Elemente für ${k.id}`;
    case 'SearchResultHarvestSeason': return `Elemente für Ernte in ` + t(k.id);
    case 'SearchResultHarvestYear': return `Elemente für Ernte von ${k.id}`;
    case 'SearchResultUserGroup': return `portfolio ${k.id}`;
    case 'SelectCount': return `${k.count} ausgewählt`;
    case 'SortBy': return `Sortiere ${k.column} ` + t(k.id);
    case 'SureAddAnotherHarvest': return `Sind Sie sicher, dass Sie diesem Feld zusätzlich zu ${k.harvestDesc} eine weitere Ernte hinzufügen möchten?`;
    case 'SureDeleteFile': return `Sind Sie sicher, dass Sie ${k.file_name} löschen möchten?`;
    case 'SureDeletePolicy': return `Sind Sie sicher, dass Sie diesen Vertrag löschen wollen? Dieser Vertrag wird aus ${intl_int(k.fieldCount)} Feldern und ${intl_int(k.sampleCount)} Proben entfernt, aber diese werden nicht gelöscht.`;
    case 'SureRemoveFarm': return `Möchten Sie diesen Betrieb und ihre ${intl_int(k.fieldCount)} Felder wirklich entfernen?`;
    case 'SureRemoveSelectedOfflineFarms': return `Sind Sie sicher, dass Sie den Offlinezugriff für ${k.count} ausgewählte Betriebe entfernen möchten?`;
    case 'TelepacReportEmail': return `Ein Importbericht wurde Ihnen an ${k.email} gesendet, Sie können ihn auch hier herunterladen.`;
    case 'TotalAffectedAreaHarvest': return `Die gesamte betroffene Fläche für diese Ernte beträgt ${intl_num_2(k.newArea)}` + t(k.unit) + ` bei einer Erntefläche von ${intl_num_2(k.harvestArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt': return `Das aktuell betroffene Gebiet (Proben in den letzten zwei Wochen) für diese Ernte ist: ${intl_num_2(k.curArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt2': return `Dies sind alle von dieser Ernte betroffenen Gebiete: ${k.arr}.`;
    case 'TotalLossPct': return `Gesamtverlust: ${intl_num(k.totalLossPct)}%`;
    case 'TotalSubplotAreaBiggerThanField': return `Die gesamte Teilparzellenfläche (${intl_num_2(k.total_subplot_area)} ha) ist größer als die Feldfläche (${intl_num_2(k.field_area)} ha).`;
    case 'UpdateHarvestAreaMsg': return `Dadurch wird Anbaufläche der aktuellen Ernte (${k.desc}) auf ${intl_num_2(k.val)}` + t(k.unit) + ` aktualisiert.`;
    case 'UpgradeFieldHarvest': return `Auf diesem Feld liegt derzeit eine Ernte von ${k.originalHarvest} vor, die entsprechend dem Vertrag durch eine Ernte von ${k.upgradedHarvest} ersetzt wird.`;
    case 'WarnHarvestAlreadyInUse': return `Das Speichern Ihrer Änderungen an dieser vorhandenen Ernte würde sich auf ${intl_int(k.num_samples)} bereits vorhandene Proben auswirken.`;
    case 'WarningDupePolicyNumber': return `Warnung: Es gibt einen weiteren Vertrag mit der Nummer ${k.policy_number}`;
    case 'YieldMismatchWarning': return `Nicht alle Schäden wurden berücksichtigt: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` fehlt.`;
    case 'YourAreGoingToDeleteUsers': return `Sie werden ${intl_int(k.num_users)} Benutzer löschen:`;
    case 'downloadingLayers': return `Herunterladen von ${intl_int(k.count)} Layern zur Offline-Verwendung.`;
    case 'uploadingImages': return `Hochladen von Probenfotos in die Datenbank  (${intl_int(k.left)} übrig).`;
    case 'AreaUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'AreaUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
    case 'FarmDesc': return `${k.farm_name}, ${k.address}`;
    case 'FarmDesc_reference': return `${k.farm_name}, ${k.address} (${k.external_farm_id})`;
    case 'SampleDesc': return `${k.sample_date}: ${intl_num(k.estimated_yield_val)}` + t(k.estimated_yield_unit) + ` ` + t(k.crop_condition);
    case 'ValueUnit': return `${intl_num(k.val)}` + t(k.unit);
    case 'ValueUnitRange': return `${intl_num(k.min_val)}-${intl_num(k.max_val)}` + t(k.unit);
    case 'YieldUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'YieldUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
  }
}